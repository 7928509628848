<template>
  <div class="video-player">
    <a
      href="#"
      class="video-player-poster cover-img"
      @click.prevent="play"
    >
      <i>
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.799351 0.176237C1.29148 -0.0829546 1.88867 -0.0545291 2.3531 0.250131L15.3261 8.76075C15.747 9.03693 16 9.50203 16 10.0001C16 10.498 15.747 10.9631 15.3261 11.2393L2.3531 19.7499C1.88867 20.0546 1.29148 20.0829 0.799351 19.8237C0.307222 19.5646 0 19.0599 0 18.5106V1.48936C0 0.940088 0.307222 0.435408 0.799351 0.176237ZM3.02704 4.27226V15.7278L11.7578 10.0001L3.02704 4.27226Z" fill="white"/>
        </svg>
      </i>
      <img
        v-if="source === 'youtube'"
        :src="`https://img.youtube.com/vi/${id}/maxresdefault.jpg`"
        alt=""
      >
    </a>
    <div class="video-player-iframe cover-img"></div>
  </div>
</template>

<script>

export default {
  name: 'AppVideo',
  props: {
    source: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const play = $event => {
      document.querySelectorAll('.video-player').forEach(el => {
        el.classList.remove('active');
        el.querySelector('.video-player-iframe').innerHTML = '';
      })
      const player = $event.target.closest('.video-player');
      const iframeContainer = player.querySelector('.video-player-iframe');
      if(props.source === 'youtube'){
        iframeContainer.innerHTML = `
        <iframe
          src="https://www.youtube.com/embed/${props.id}?autoplay=1&playsinline=1&rel=0&mute=${$event.isTrusted === true ? 0 : 1}"
          allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>`;
      }
      else if(props.source === 'twitch'){
        iframeContainer.innerHTML = `
        <iframe
          src="https://player.twitch.tv/?channel=${props.id}&autoplay=true&parent=brave.navi.gg"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
        ></iframe>`;
       
      }
      player.classList.add('active');
    }

    return {
      play,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.video-player{
  position:relative;
  min-height:364px;
  border-radius:35px;
  overflow:hidden;
  will-change:transform;
  background: linear-gradient(139.67deg, #FFFFFF 1.75%, #8E8E8E 92.85%);
  &:before{
    content:'';
    display:block;
    padding-top:56.25%;
  }
  &.active{
    .video-player-poster{
      opacity:0;
      visibility:hidden;
    }
  }
  &:not(.active){
    .video-player-iframe{
      opacity:0;
      visibility:hidden;
    }
  }
}

.video-player-poster,
.video-player-iframe{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  transition:opacity .4s,visibility .4s;
}

.video-player-poster{
  i{
    position:absolute;
    width:64px;
    height:64px;
    border-radius:50%;
    background-color:@link;
    transition:background-color .4s,transform .4s;
    left:50%;
    top:50%;
    margin:-32px 0 0 -32px;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:1;
    padding-left:4px;
    path{
      fill:var(--white);
    }
  }
  &:hover{
    i{
      background-color:@link_;
      transform:scale(.8);
    }
  }
  img{
    pointer-events:none;
  }
}

.video-player-iframe{
  :deep(iframe){
    border:0;
  }
}

</style>
