import { createWebHistory, createRouter } from "vue-router";
import TheHome from "@/views/TheHome.vue";

const routes = [
  {
    path: '/:lang(en|uk)?',
    name: 'TheHome',
    component: TheHome,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 }
    }
  },
});

export default router
