export const lottery = [
  {
    imgWidth: 136,
    title: {
      en: 'Desert Eagle | Blaze. Personally from b1t from PGL Major Antwerp 2022',
      uk: 'Desert Eagle | Blaze. Personally from b1t from PGL Major Antwerp 2022',
    },
  },
  {
    imgWidth: 136,
    title: {
      en: 'm4A1-s | Blue phosphor. Personally from sdy from BLAST Premier: Spring Finals 2022',
      uk: 'm4A1-s | Blue phosphor. Personally from sdy from BLAST Premier: Spring Finals 2022',
    },
  },
  {
    imgWidth: 136,
    title: {
      en: 'Glock-18 | Fade. Personally from s1mple from PGL Major Antwerp 2022',
      uk: 'Glock-18 | Fade. Personally from s1mple from PGL Major Antwerp 2022',
    },
  },
  {
    imgWidth: 740,
  },
];
