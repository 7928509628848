<template>
  <div
    id="donations-map" 
    class="donations-map section"
  >
    <div class="container">
      <div class="section-head">
        <h2 class="section-title">{{t('home.donationsMap.title')}}</h2>
        <div
          class="section-desc"
          v-html="t('home.donationsMap.desc')"
        ></div>
      </div>
      <div class="maps">
        <div
          class="map-item"
          v-for="(map, index) in maps"
          :key="index"
          :id="`map-item-${index}`"
          :class="map.vote ? 'map-item-with-vote' : ''"
        > 
          <div class="map-item-body">
            <div
              class="map-item-proggress"
              :style="{
                width: getProgressWidth(index),
                height: getProgressWidth(index),
              }"
            >
            </div>
            <div class="map-item-content">
              <div class="map-item-img">
                <AppPicture
                  :src="map.img"
                  width="150"
                />
              </div>
              <div class="map-item-info">
                <div class="map-item-sum">{{map.sumTitle}}</div>
                <div
                  class="map-item-title"
                  @click="openMapItemDesc"
                >{{map.title[locale]}}</div>
                <div
                  class="map-item-desc"
                  v-html="map.desc[locale]"
                  @click="closeMapItemDesc"
                ></div>
              </div>
              <button
                v-if="map.vote"
                type="button"
                class="map-item-btn map-item-vote-open-btn"
                :class="currentAmount / map.sum >= 1 ? 'active' : ''"
                @click="openVote"
              >
                {{map.vote && voted[map.vote.name] ? t('home.donationsMap.btns.results') : t('home.donationsMap.btns.open')}}
              </button>
            </div>
          </div>
          <div
            v-if="map.vote"
            class="map-item-vote"
            :data-checke-count="map.vote.checkedCount"
          >
            <div class="map-item-vote-title">{{map.title[locale]}}</div>
            <div class="map-item-vote-list-wrapp">
              <ul class="map-item-vote-list">
                <li
                  v-for="(checkbox, i) in map.vote.list"
                  :key="i"
                >
                  <div class="map-item-vote-checkbox">
                    <input
                      :id="`map-vote-${index}-${i}`"
                      type="checkbox"
                      :name="`map-vote-${index}`"
                      :value="checkbox.value"
                      :checked="voted[map.vote.name] && voted[map.vote.name].includes(checkbox.value)"
                      @change="checkVoteLimit($event, index)"
                      :disabled="voted[map.vote.name]"
                      :class="voted[map.vote.name] ? 'disabled' : ''"
                    >
                    <label :for="`map-vote-${index}-${i}`">
                      <i>
                        <img src="/img/checkbox.svg" alt="">
                      </i>
                      <span>{{checkbox.name}}</span>
                    </label>
                  </div>
                  <div
                    v-if="voted[map.vote.name]"
                    class="map-item-vote-progress visible-tablet">
                    <div
                      class="map-item-vote-progress-state"
                      :style="{width: checkbox.percent + '%'}"
                    ></div>
                  </div>
                  <div
                    v-if="voted[map.vote.name]"
                    class="map-item-vote-value"
                  >{{checkbox.percent}}%</div>
                </li>
              </ul>
            </div>
            <button
              v-if="voted[map.vote.name]"
              type="button"
              class="map-item-btn map-item-vote-close-btn"
              @click="closeVote"
            >
              {{t('home.donationsMap.btns.close')}}
            </button>
            <button
              v-if="!voted[map.vote.name]"
              type="button"
              class="map-item-btn map-item-vote-save-btn"
              @click="saveVote($event, map.vote.name)"
            >
              {{t('home.donationsMap.btns.save')}}
            </button>
          </div>
        </div>
      </div>
      <div class="map-btn-wrapp">
        <AppButton
          :href="donateLink"
          class="btn-full-width"
          target="_blank"
        >
          {{t('home.donationsMap.donateBtn')}}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import AppButton from '@/components/AppButton.vue';
import AppPicture from '@/components/AppPicture.vue';
import { slideUp, slideDown, scrollTo } from '@/helpers/functions';
import { maps } from '@/data/maps';
import { putData } from '@/helpers/api'

export default {
  name: 'HomeDonationsMap',
  props: {
    currentAmount: {
      type: Number,
      default: 0,
    },
    donateLink: {
      type: String,
      default: '#',
    },
    maps: {
      type: Array,
      required: true,
    },
    userID: {
      type: String,
      required: true,
    },
  },
  components: {
    AppButton,
    AppPicture,
  },
  setup(props) {

    const { t, locale } = useI18n({ useScope: 'global' });

    const getProgressWidth = mapItemIndex => {
      const end = maps[mapItemIndex].sum;
      const start = maps[mapItemIndex - 1] ? maps[mapItemIndex - 1].sum : 300000;
      const diff = start - end;
      const value = start - props.currentAmount;
      return (value * 100 / diff) + '%';
    }

    const openVote = $event => {
      const mapItem = $event.target.closest('.map-item');
      mapItem.classList.add('active');
      if(window.matchMedia('(max-width:1023px)').matches){
        slideUp(mapItem.querySelector('.map-item-body'));
        slideDown(mapItem.querySelector('.map-item-vote'));
        setTimeout(() => scrollTo(mapItem.id), 400);
      }
    }

    const closeVote = $event => {
      const mapItem = $event.target.closest('.map-item');
      mapItem.classList.remove('active');
      if(window.matchMedia('(max-width:1023px)').matches){
        slideDown(mapItem.querySelector('.map-item-body'));
        slideUp(mapItem.querySelector('.map-item-vote'));
      }
    }

    const voted = ref({});
    if(localStorage.getItem('voted')){
      try{
        voted.value = {...JSON.parse(localStorage.getItem('voted'))}
      }catch(error){
        console.log(error);
      }
    }

    const saveVote = ($event, name) => {
      const inputs = $event.target.closest('.map-item').querySelectorAll('input:checked');
      const values = [];
      inputs.forEach(input => values.push(input.value));
      if(values.length){
        voted.value[name] = [...values];
        localStorage.setItem('voted', JSON.stringify(voted.value));
        putData('https://logitech.navi.gg/api/direct/brave?type=PostMap', {
          user_id: props.userID,
          name: name,
          values: values,
        });
      }else{
        closeVote($event);
      }
    }

    const checkVoteLimit = ($event, index) => {
      const inputs = $event.target.closest('.map-item').querySelectorAll('input:checked');
      const checkedCount = props.maps[index].vote.checkedCount;
      if(inputs.length === checkedCount){
        $event.target.closest('.map-item').querySelectorAll('input:not(:checked)').forEach(el => {
          el.setAttribute('disabled', true);
        });
      }else{
        $event.target.closest('.map-item').querySelectorAll('input').forEach(el => {
          el.removeAttribute('disabled');
        });
      }
    }

    const openMapItemDesc = $event => {
      if(window.matchMedia('(min-width:1024px)').matches){
        return;
      }
      closeMapItemDesc();
      $event.target.closest('.map-item').classList.add('desc-is-visible');
    }

    const closeMapItemDesc = () => {
      if(window.matchMedia('(min-width:1024px)').matches){
        return;
      }
      document.querySelectorAll('.map-item').forEach(el => el.classList.remove('desc-is-visible'));
    }

    return {
      t,
      locale,
      getProgressWidth,
      openVote,
      saveVote,
      closeVote,
      voted,
      checkVoteLimit,
      openMapItemDesc,
      closeMapItemDesc,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.donations-map{
  position:relative;
  z-index:3;
}

.maps{
  display:flex;
  padding-right:2px;
  @media @md{
    flex-direction:column-reverse;
    padding:0 0 2px;
  }
  @media @xs{
    margin:0 calc(var(--container-padding) * -1);
  }
}

.map-item{
  @media @md_{
    width:25%;
    position:relative;
    display:flex;
    flex-direction:column;
    perspective:1000px;
    margin-right:-2px;
  }
  @media @md{
    margin-bottom:-2px;
    &.desc-is-visible{
      .map-item-img,
      .map-item-title,
      .map-item-sum,
      :deep(.map-item-vote-open-btn){
        opacity:0;
        visibility:hidden;
        pointer-events:none;
      }
      .map-item-desc{
        opacity:1;
        visibility:visible;
        pointer-events:auto;
      }
    }
  }
}

.map-item-with-vote{
  @media @md_{
    &.active{
      .map-item-body{
        transform:rotateY(-180deg);
      }
      .map-item-vote{
        transform:rotateY(0);
      }
    }
  }
}

.map-item-body{
  position:relative;
  border:solid 2px @link;
  overflow:hidden;
  will-change:transform;
  border-radius:35px;
  @media @md_{
    flex:auto;
    display:flex!important;
    flex-direction:column;
    transform-style:preserve3d;
    transition:transform .4s;
    backface-visibility:hidden;
  }
}

.map-item-proggress{
  position:absolute;
  left:0;
  bottom:0;
  top:0;
  background-color:@link;
  @media @md_{
    height:auto!important;
  }
  @media @md{
    width:auto!important;
    top:auto;
    right:0;
  }
}

.map-item-content{
  flex:auto;
  position:relative;
  z-index:6;
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:20px 16px 38px;
  text-align:center;
  @media @md{
    flex-direction:row;
    text-align:left;
    padding:20px;
    min-height:130px;
  }
}

.map-item-img{
  display:flex;
  align-items:center;
  justify-content:center;
  width:150px;
  height:150px;
  border-radius:50%;
  margin:-20px 0 0;
  @media @md{
    width:118px;
    height:118px;
    margin:-14px -4px -14px -26px; 
    flex:none;
    transition:opacity .4s, visibility .4s;
  }
}

.map-item-info{
  margin-bottom:20px;
  @media @md{
    flex:auto;
    min-width:1px;
    margin:0 16px 0 0;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
  }
  &:last-child{
    margin:0;
  }
}

.map-item-sum{
  font-size:64px;
  line-height:.8;
  letter-spacing:-0.025em;
  margin-bottom:10px;
  text-transform:uppercase;
  font-family:@ff_;
  color:@link_;
  @media @md{
    font-size:36px;
    margin-bottom:6px;
    transition:opacity .4s, visibility .4s;
  }
}

.map-item-title{
  text-transform:uppercase;
  font-family:@ff_;
  font-size:36px;
  line-height:.9;
  margin-bottom:24px;
  @media @md{
    padding-bottom:4px;
    border-bottom:dashed 1px;
    margin:0 0 -4px;
    cursor:pointer;
    user-select:none;
    transition:opacity .4s, visibility .4s;
  }
}

.map-item-desc{
  font-size:16px;
  line-height:1.2;
  @media @md{
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    display:flex;
    flex-direction:column;
    justify-content:center;
    z-index:6;
    padding:24px 56px;
    text-align:center;
    opacity:0;
    visibility:hidden;
    pointer-events:none;
    transition:opacity .4s, visibility .4s;
  }
}

.map-item-btn{
  width:190px;
  height:50px;
  max-width:100%;
  display:flex;
  align-items:center;
  justify-content:center;
  text-transform:uppercase;
  font-family:@ff_;
  font-size:36px;
  line-height:.9;
  padding:0 24px;
  transition:color .4s,border-color .4s,background-color .4s;
  border-radius:100px;
  border:solid 2px @link_;
  color:@link_;
  margin-top:auto;
  flex:none;
  @media @md{
    width:120px;
    padding:0 12px;
    margin:0;
  }
  &:hover{
    color:@link;
    background-color:@link_;
  }
}

.map-item-vote-open-btn{
  &:not(.active){
    border-color:rgba(255, 255, 255, 0.2);
    color:rgba(255, 255, 255, 0.2);
    pointer-events:none;
  }
}

.map-item-vote{
  border-radius:35px;
  background-color:@link_;
  @media @md_{
    position:absolute;
    z-index:6;
    left:0;
    right:0;
    bottom:0;
    top:0;
    transform-style:preserve3d;
    transition:transform;
    transform:rotateY(180deg);
    backface-visibility:hidden;
    transition:transform .4s;
    will-change:transform;
    overflow:hidden;
    display:flex!important;
    flex-direction:column;
    align-items:center;
    padding:24px 28px 36px;
    @media @lg{
      padding:32px 20px 36px;
    }
  }
  @media @md{
    display:none;
    // display:flex;
    align-items:center;
    flex-wrap:wrap;
    padding:28px 24px 48px;
  }
}

.map-item-vote-title{
  text-align:center;
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  color:@link;
  flex:none;
  @media @md{
    flex:auto;
    width:calc(100% - 120px);
    padding:0 16px 0 46px;
    text-align:left;
  }
}

.map-item-vote-list-wrapp{
  @media @md_{
    margin:0 -28px;
    padding:16px 0;
    min-height:1px;
    flex:auto;
    align-self:stretch;
    position:relative;
    @media @lg{
      margin:0 -20px;
    }
    &:before,
    &:after{
      content:'';
      position:absolute;
      left:24px;
      right:24px;
      height:16px;
      z-index:6;
    }
    &:before{
      top:0;
      background-image:linear-gradient(to bottom,@link_,rgba(var(--yellow-rgb),0));
    }
    &:after{
      bottom:0;
      background-image:linear-gradient(to top,@link_,rgba(var(--yellow-rgb),0));
    }
  }
  @media @md{
    width:100%;
    order:1;
    margin-top:14px;
  }
}

.map-item-vote-list{
  @media @md_{
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    overflow-y:auto;
    padding:16px 28px;
    @media @lg{
      padding:16px 20px;
    }
  }
  font-size:16px;
  line-height:1px;
  li{
    display:flex;
    align-items:center;
  }
}

.map-item-vote-checkbox{
  flex:auto;
  min-width:1px;
  input{
    display:none;
    &:checked+label{
      i{
        background-color:@link;
        img{
          transform:scale(1);
          opacity:1;
        }
      }
    }
    &[disabled]:not(.disabled)+label{
      opacity:.4;
    }
    &[disabled]:not(:checked).disabled+label{
      @media (hover:hover){
        &:hover{
          i{
            background:none;
          }
        }
      }
    }
    &[disabled]+label{
      cursor:unset;
    }
  }
  label{
    display:flex;
    align-items:flex-start;
    color:@link;
    cursor:pointer;
    user-select:none;
    transition:opacity .4s;
    @media (hover:hover){
      &:hover{
        i{
          background-color:@link;
        }
      }
    }
    i{
      width:30px;
      height:30px;
      flex:none;
      border-radius:50%;
      margin-right:16px;
      border:solid 2px @link;
      display:flex;
      align-items:center;
      justify-content:center;
      transition:background-color .4s;
      img{
        opacity:0;
        transform:scale .4s;
        transition:opacity .4s,transform .4s;
      }
    }
    span{
      min-width:1px;
      align-self:center;
    }
  }
}

.map-item-vote-progress{
  width:112px;
  flex:none;
  margin-left:20px;
  height:10px;
  overflow:hidden;
  will-change:transform;
  border-radius:100px;
}

.map-item-vote-progress-state{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  background-color:@link;
  border-radius:100px;
}

.map-item-vote-value{
  flex:none;
  text-align:right;
  width:32px;
  margin-left:20px;
  font-size:16px;
  line-height:1.2;
  color:@link;
}

.map-item-vote-close-btn,
.map-item-vote-save-btn{
  border-color:@link;
  color:@link;
  &:hover{
    color:@link_;
    background-color:@link;
  }
}

.map-btn-wrapp{
  @media @xs{
    margin:0 calc(var(--container-padding) * -1);
  }
}

</style>
