export const commentators = [
  {
    img: 'img/commentator-1.png?v2',
    name: {
      en: 'Vlаdуslаv <span>"bondik"</span> Nеchуроrchuk',
      uk:  'Vlаdуslаv <span>"bondik"</span> Nеchуроrchuk',
    },
    flag: 'ukraine',
    twitter: 'https://twitter.com/bondik_csgo',
    instagram: '',
    color: 'var(--blue)',
  },
  {
    img: 'img/commentator-2.png',
    name: {
      en: 'Dmytro <span>"def"</span> Lemeshchuk',
      uk:  'Dmytro <span>"def"</span> Lemeshchuk',
    },
    flag: 'ukraine',
    twitter: 'https://twitter.com/defcsgo',
    instagram: '',
    color: 'var(--blue)',
  },
  {
    img: 'img/commentator-3.png',
    name: {
      en: 'Fedir <span>"KvaN"</span> Zakharov',
      uk:  'Fedir <span>"KvaN"</span> Zakharov',
    },
    flag: 'ukraine',
    twitter: 'https://twitter.com/__kvan',
    instagram: '',
    color: 'var(--blue)',
  },
  {
    img: 'img/commentator-4.png',
    name: {
      en: 'Serhii <span>"lmbt"</span> Bezhanov',
      uk:  'Serhii <span>"lmbt"</span> Bezhanov',
    },
    flag: 'ukraine',
    twitter: 'https://twitter.com/lmbt_csgo',
    instagram: '',
    color: 'var(--blue)',
  },
  {
    img: 'img/commentator-5.png',
    name: {
      en: 'Dan <span>"Loran"</span> Juryd',
      uk:  'Dan <span>"Loran"</span> Juryd',
    },
    flag: 'ukraine',
    twitter: 'https://twitter.com/Loran_Stream',
    instagram: '',
    color: 'var(--blue)',
  },
  {
    img: 'img/commentator-6.png',
    name: {
      en: 'Adam <span>"Dinko"</span> Hawthorne',
      uk:  'Adam <span>"Dinko"</span> Hawthorne',
    },
    flag: 'united-kingdom',
    twitter: 'https://twitter.com/dinkotv',
    instagram: '',
    color: 'var(--yellow)',
  },
  {
    img: 'img/commentator-7.png',
    name: {
      en: 'Jonatan <span>"Devilwalk"</span> Lundberg',
      uk:  'Jonatan <span>"Devilwalk"</span> Lundberg',
    },
    flag: 'united-kingdom',
    twitter: 'https://twitter.com/MosesGG',
    instagram: '',
    color: 'var(--yellow)',
  },
  {
    img: 'img/commentator-8.png',
    name: {
      en: 'james <span>"BanKs"</span> Banks',
      uk:  'james <span>"BanKs"</span> Banks',
    },
    flag: 'united-kingdom',
    twitter: 'https://twitter.com/BanKsEsports',
    instagram: '',
    color: 'var(--yellow)',
  },
  {
    img: 'img/commentator-9.png',
    name: {
      en: 'Halvor <span>"vENdetta"</span> Gulestøl',
      uk:  'Halvor <span>"vENdetta"</span> Gulestøl',
    },
    flag: 'united-kingdom',
    twitter: 'https://twitter.com/vENdettaCSGO',
    instagram: '',
    color: 'var(--yellow)',
  },
  {
    img: 'img/commentator-10.png',
    name: {
      en: 'Jason <span>"moses"</span> O\'Toole',
      uk:  'Jason <span>"moses"</span> O\'Toole',
    },
    flag: 'united-kingdom',
    twitter: 'https://twitter.com/DevilwalkCSGOD',
    instagram: '',
    color: 'var(--yellow)',
  },
];
