<template>
  <div
    id="promo" 
    class="promo section"
  >
    <div class="container">
      <div class="promo-img promo-img-left">
        <AppPicture
          src="promo-img-left.png"
          width="671"
          class="hidden-tablet"
        />
        <AppPicture
          src="promo-img-left-mobile.png"
          width="256"
          class="visible-tablet"
        />
      </div>
      <div class="promo-body">
        <div class="promo-logo">
          <img src="/img/logo.gif" alt="Navi Logo">
        </div>
        <div class="promo-title">
          <img
            src="/img/promo-title.svg"
            alt="Born To Be Brave"
          >
        </div>
        <div class="promo-btn-wrapp">
          <AppButton
            class="promo-donate-btn"
            :href="donateLink"
            target="_blank"
          >
            {{t('home.promo.donateBtn')}}
          </AppButton>
        </div>
        <div
          class="promo-desc"
          v-html="t('home.promo.desc')"
        ></div>
        <a
          href="#donations-map"
          @click.prevent="scrollTo('#donations-map')"
          class="promo-scroll-to-btn"
        >
          <i>
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.0711 10.3431L8.70716 16.7071C8.31664 17.0976 7.68347 17.0976 7.29295 16.7071L0.928985 10.3431C0.538461 9.95262 0.538461 9.31946 0.928985 8.92893C1.31951 8.53841 1.95267 8.53841 2.3432 8.92893L7.00005 13.5858L7.00005 1C7.00005 0.447716 7.44777 3.4603e-07 8.00005 3.21889e-07C8.55234 2.97748e-07 9.00005 0.447716 9.00005 1L9.00005 13.5858L13.6569 8.92893C14.0474 8.53841 14.6806 8.53841 15.0711 8.92893C15.4616 9.31946 15.4616 9.95262 15.0711 10.3431Z" fill="#FFD700"/>
            </svg>
          </i>
          <span>{{t('home.promo.scrollToBtn')}}</span>
        </a>
      </div>
      <div class="promo-img promo-img-right">
        <AppPicture
          src="promo-img-right.png"
          width="671"
          class="hidden-tablet"
        />
        <AppPicture
          src="promo-img-right-mobile.png"
          width="256"
          class="visible-tablet"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { scrollTo } from '@/helpers/functions';
import AppButton from '@/components/AppButton.vue';
import AppPicture from '@/components/AppPicture.vue';

export default {
  name: 'HomePromo',
  components: {
    AppButton,
    AppPicture,
  },
  props: {
    donateLink: {
      type: String,
      default: '#',
    },
  },
  setup() {

    const { t } = useI18n({ useScope: 'global' });

    return {
      t,
      scrollTo,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.promo{
  padding-top:0;
  position:relative;
  .container{
    display:flex;
    justify-content:center;
    align-items:flex-start;
  }
}

.promo-img{
  display:flex;
  flex-direction:column;
  width:0;
  margin-top:40px;
  position:relative;
  @media @md{
   margin:0;
   position:absolute;
   left:50%;
   top:24px;
  }
  &:after{
    content:'';
    position:absolute;
    height:345px;
    bottom:0;
    z-index:1;
    width:1000px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 79.26%);
    @media @md{
      height:292px;
    }
  }
  :deep(img){
    max-width:none;
  }
}

.promo-img-left{
  align-items:flex-end;
  :deep(img){
    margin-right:-420px;
    @media @lg{
      margin-right:-450px;
    }
    @media @md{
      margin-right:0;
    }
  }
  &:after{
    right:-450px;
    @media @md{
      right:0;
    }
  }
}

.promo-img-right{
  align-items:flex-start;
  :deep(img){
    margin-left:-420px;
    @media @lg{
      margin-left:-450px;
    }
    @media @md{
      margin-left:0;
    }
  }
  &:after{
    left:-450px;
    @media @md{
      left:0;
    }
  }
}

.promo-body{
  flex:none;
  width:1016px;
  display:flex;
  flex-direction:column;
  align-items:center;
  font-size:36px;
  line-height:.9;
  text-align:center;
  position:relative;
  z-index:6;
  max-width:100%;
}

.promo-date{
  color:@link_;
  margin-bottom:28px;
  font-family:@ff_;
  height:var(--header-height);
  display:flex;
  align-items:center;
  justify-content:center;
  @media @md{
    font-size:36px;
  }
}

.promo-logo{
  margin-bottom:12px;
  :deep(img){
    @media @md{
      width:112px;
    }
  }
}

.promo-title{
  margin-bottom:32px;
  @media @md{
    width:134px;
    margin-bottom:20px;
  }
  img{
    display:block;
  }
}

.promo-btn-wrapp{
  align-self: stretch;
  display:flex;
  flex-direction:column;
  align-items:center;
  margin-bottom:30px;
  @media @xs{
    margin:0 calc(var(--container-padding) * -1) 20px;
  }
}

.promo-donate-btn{
  width:375px;
  @media @xs{
    width:100%;
  }
}

.promo-desc{
  font-family:@ff_;
  margin-bottom:20px;
  @media @md{
    font-size:24px;
    width:318px;
    margin-bottom:16px;
  }
  :deep(span){
    position:relative;
    color:@link_;
    cursor:pointer;
    &:hover{
      &:before{
        opacity:0;
      }
      &:after{
        opacity:1;
      }
    }
    &:before{
      content:attr(data-text);
      transition:opacity .4s;
    }
    &:after{
      content:attr(data-title);
      position:absolute;
      left:0;
      top:50%;
      transform:translateY(-50%);
      right:0;
      text-align:center;
      opacity:0;
      transition:opacity .4s;
    }
  }
}

.promo-scroll-to-btn{
  display:flex;
  flex-direction:column;
  align-items:center;
  color:@link_;
  transition:color .4s;
  font-size:16px;
  &:hover{
    color:@link;
  }
  i{
    display:flex;
    align-items:center;
    justify-content:center;
    width:58px;
    height:58px;
    border-radius:50%;
    border:solid 2px;
    margin-bottom:6px;
  }
  svg{
    animation:promo-scroll-to-btn 2s infinite;
  }
  path{
    fill:currentColor;
  }
}

@keyframes promo-scroll-to-btn{
  40%{
    transform:translateY(-8px);
  }
  70%{
    transform:translateY(8px);
  }
}

</style>
