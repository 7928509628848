<template>
  <div class="lottery-progress">
    <div class="lottery-progress-line">
      <div
        class="lottery-progress-line-state"
        :style="{width: getProgressWidth()}"
      ></div>
    </div>
    <div class="lottery-progress-info">
      <div class="lottery-progress-info-item">
        <span>{{t('home.lottery.now')}}:</span>
        <span>${{replacePriceValue(now)}}</span>
      </div>
      <div class="lottery-progress-info-item">
        <span>{{t('home.lottery.goal')}}:</span>
        <span>${{replacePriceValue(goal)}}</span>
      </div>
    </div>
  </div>
</template>

<script>

// import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'LotteryProgress',
  props: {
    now: {
      type: [Number, String],
      required: true,
    },
    goal: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {

    const { t } = useI18n({ useScope: 'global' });

    const replacePriceValue = value => {
      if(value === undefined){
        return '';
      }
      value = value.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
      return value.replace('.',',');
    }

    const getProgressWidth = () => {
      return (parseInt(props.now, 10) * 100 / parseInt(props.goal, 10)) + '%';
    }

    return {
      t,
      replacePriceValue,
      getProgressWidth,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';
  
.lottery-progress{
  width:324px;
  margin-left:auto;
  margin-right:auto;
  max-width:100%;
  position:relative;
  z-index:6;
}

.lottery-progress-line{
  position:relative;
  overflow:hidden;
  will-change:transform;
  border:solid 2px var(--black);
  border-radius:100px;
  margin-bottom:6px;
  height:18px;
}

.lottery-progress-line-state{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  background-color:var(--black);
}

.lottery-progress-info{
  padding:0 8px;
  display:flex;
  justify-content:space-between;
  white-space:nowrap;
  font-size:16px;
  line-height:1.2;
  color:var(--black);
}

.lottery-progress-info-item{
  margin-right:12px;
  display:flex;
  span{
    margin-right:6px;
    &:last-child{
      margin:0;
    }
  }
  &:last-child{
    margin:0;
  }
}

</style>
