export default {
  head: {
    title: "NAVI {'|'} BORN TO BE BRAVE",
  },
  header: {
    ariaLabels: {
      navOpen: 'Navigation',
    },
    date: '22 December',
    dateMobile: '22.12',
    donateBtn: 'Donate',
    donateBtnClassic: 'Donate',
    donateBtnCrypto: 'Donate in crypto',
    cryptoModal: {
      closeBtn: 'CLOSE',
      copyBtn: 'COPY',
      info: `
        <p>Donations in crypto are not eligible to compete in the lotteries.
        <p>However, if you want to buy an item from the fixed price prizes section, drop us an email at <a href="mailto:brave{'@'}navi.gg" target="_blank">brave{'@'}navi.gg</a> and we'll figure out how to arrange this.
      `
    },
    menu: {
      format: 'Format',
      donationsMap: 'Donations Map',
      lottery: 'Lottery',
      prizes: 'Prizes',
      about: 'WHERE THE MONEY GO',
    },
    rules: {
      link: 'Rules',
      title: 'How to donate',
      close: 'Close',
      content: `
        <p>It's simple! We offer three types of donations to support Ukraine through UNITED24.
        <p>1. We appreciate everyone's desire to help. Therefore, even the smallest donations affect the course of the tournament, reaching milestones on the donation map.
        <p>2. All donations that participate in lotteries must be multiples of the specified lottery entry amount. The bigger the donation, the more chances to win. You can participate in the lotteries from December 12 to 22. We will announce the winners after the stream.
        <p>3. Prize for donation: for donating a specific amount, you're guaranteed to receive a gift.
        <p style="color:var(--yellow);">IMPORTANT:<br>
        Be sure to enter your email in the «comment» section of the donor box. Otherwise, we won't be able to contact you if you win. That's important!<br>
        All funds collected will go to the UNITED24 presidential initiative for the medical needs of Ukraine. So send donations, receive gifts, and support Ukraine. If you're a company that wants to donate — please reach out to us via email:  <a href="mailto:brave{'@'}navi.gg" target="_blank">brave{'@'}navi.gg</a>
      `,
    }
  },
  home: {
    promo: {
      desc: `
        NAVI devotes its
        <span data-text="birthday" data-title="13 years"></span>
        to the BORN TO BE BRAVE CS:GO charity tournament. The best esports clubs and players will raise donations for Ukraine with UNITED24.
      `,
      donateBtn: 'donate now',
      scrollToBtn: 'Check donation map',
    },
    broadcast: {
      donateBtn: 'donate now',
      desc: 'Take advantage of influencing the course of the tournament<br> with your <span>donations to Ukraine</span> and get <span>special prizes</span>',
    },
    format: {
      title: '1 DAY, 4 TEAMS AND 20 PLAYERS',
      desc: 'Your favorite maps and interactive modes',
      teamsDesc: 'Rosters are announced as soon as the «Rosters Unlock» milestone is achieved.',
    },
    donationsMap: {
      title: 'Donations MAP',
      desc: 'Only fans can influence the course of the tournament.<br>Make your donations to buy special prizes and reach the milestones on the donation map.',
      btns: {
        open: 'VOTE',
        save: 'SAVE',
        close: 'CLOSE',
        results: 'RESULTS',
      },
      donateBtn: 'DONATE NOW',
    },
    trip: {
      title: 'WIN trip to major',
      desc: 'The top prize goes to the most generous fan who has contributed the most to<br> the BORN TO BE BRAVE fundraising event. To participate, you can simply donate<br> or compete for any prize below, we\'ll add up all of your donations across all lotteries.',
      info: {
        title: 'The winner will receive:',
        content: `
        <ul>
          <li>VIP ticket to the Major
          <li>Backstage access to the tournament
          <li>Meet the team in the practice room
          <li>Bundle of signed merch
          <li>1st class plane tickets and accommodation in a 5* hotel
          <li>Access to a personal driver across the entire length of the tournament
          <li>Entertainment program
          <li>A walk along the Seine and dinner with GG.BET ambassadors
          <li>Meet Team Vitality and tour the V.Hive
        </ul>
        `,
        desc: 'If the winner is not currently able to travel abroad, this tournament<br> can be substituted with another Major when the borders are opened.',
      },
      leaderboard: 'Leaderboard',
      place: 'PLACE',
    },
    lottery: {
      title: 'LOTTERY for the bravest',
      desc: 'Make a donation for the prize you liked the most to participate in the lottery. The price of<br> the entry ticket is specified on each prize. We will choose the winners after the stream<br> when the total amount of donations reaches the goal amount. You can donate to<br> different prizes. More tickets mean more chances to win.',
      prizesTitle: 'x3 skins + superprize',
      donateBtn: 'one ticket',
      buyBtn: 'PARTICIPATE',
      closeBtn: 'close',
      now: 'Raised',
      goal: 'To unlock',
      prizesPopupTitle: 'Skins + G PRO pack',
      prizesPopupDesc: 'Your chance to win 1 of 3 skins personally from NAVI players and a G PRO bundle from Logitech G, which includes a PRO RACING WHEEL, PRO RACING PEDALS, G PRO X Superlight mouse, G PRO keyboard and G PRO X headset.',
    },
    prizes: {
      title: 'FIXED price prizes',
      desc: 'If you don\'t like surprises but love gifts, this is the segment for you — a guaranteed prize for your donation.',
      buyBtn: 'BUY NOW',
      outBtn: 'sold out',
    },
    maincast: {
      desc: 'Broadcast of the event is powered by Ukrainian company Maincast',
    },
    about: {
      title: 'WHERE<br class="visible-mobile"> THE MONEY GO',
      content: `
      The initiative of the President of Ukraine<br>
      UNITED24 was launched as the main venue for collecting charitable donations in support of Ukraine.<br>
      Funds will be transferred to the official accounts of the National Bank of Ukraine to cover medical aid needs.
      `,
      total: 'ALREADY RAISED <span>$237 000 000+</span>',
      donateBtn: 'DONATE NOW',
    },
  },
  footer: {
    terms: 'Terms and Conditions',
    link: 'https://drive.google.com/file/d/1-iQoLT4o1-NbCJ_QVqk1drViKMK0TEaP/view?usp=share_link',
    copyright: 'Natus Vincere 2009-2022 ©',
  },
}
