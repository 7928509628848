export const teams = [
  {
    name: 'team rush a rush b',
    members: [
      {
        icon: 'team-icon-13.png',
        iconWidth: 32,
        name: 'mezii',
      },
      {
        icon: 'team-icon-9.png',
        iconWidth: 24,
        name: 'tabseN',
      },
      {
        icon: 'team-icon-2.png',
        iconWidth: 28,
        name: 'apEX',
      },
      {
        icon: 'team-icon-4.png',
        iconWidth: 31,
        name: 'gla1ve',
      },
      {
        icon: 'team-icon-10.png',
        iconWidth: 32,
        name: 'Snappi',
      },
    ],
  },
  {
    name: 'team oneshot',
    members: [
      {
        icon: 'team-icon-6.png',
        iconWidth: 39,
        name: 'S1mple',
      },
      {
        icon: 'team-icon-2.png',
        iconWidth: 28,
        name: 'ZywOo',
      },
      {
        icon: 'team-icon-11.png',
        iconWidth: 29,
        name: 'w0nderful',
      },
      {
        icon: 'team-icon-4.png',
        iconWidth: 31,
        name: 'dev1ce',
      },
      {
        icon: 'team-icon-7.png',
        iconWidth: 36,
        name: 'broky',
      },
    ],
  },
  {
    name: 'team gokill',
    members: [
      {
        icon: 'team-icon-6.png',
        iconWidth: 39,
        name: 'b1t',
      },
      {
        icon: 'team-icon-8.png',
        iconWidth: 29,
        name: 'YEKINDAR',
      },
      {
        icon: 'team-icon-14.png',
        iconWidth: 31,
        name: 'k0nfig',
      },
      {
        icon: 'team-icon-7.png',
        iconWidth: 36,
        name: 'Twistzz',
      },
      
      {
        icon: 'team-icon-12.png',
        iconWidth: 27,
        name: 'xertioN',
      },
    ],
  },
  {
    name: 'team you shall not pass',
    members: [
      {
        icon: 'team-icon-10.png',
        iconWidth: 32,
        name: 'dycha',
      },
      {
        icon: 'team-icon-6.png',
        iconWidth: 39,
        name: 'sdy',
      },
      {
        icon: 'team-icon-2.png',
        iconWidth: 28,
        name: 'Magisk',
      },
      {
        icon: 'team-icon-12.png',
        iconWidth: 27,
        name: 'frozen',
      },
      {
        icon: 'team-icon-4.png',
        iconWidth: 31,
        name: 'Xyp9x',
      },
    ],
  },
];
