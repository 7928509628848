<template>
  <div
    id="maincast" 
    class="maincast section"
  >
    <div class="container">
      <div class="section-head">
        <AppPicture
          src="maincast.png"
          width="339"
          className="maincast-img-title"
          alt="Maincast"
        />
        <div
          class="section-desc"
          v-html="t('home.maincast.desc')"
        ></div>
      </div>
      <div class="commentators-slider-wrapp slider-wrapp">
        <SliderArrows/>
        <div
          class="commentators-slider swiper"
          ref="slider"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(commentator, index) in commentators"
              :key="index"
              class="commentators-slide swiper-slide"
            >
              <div class="commentators-item">
                <div
                  class="commentators-item-img cover-img"
                  :style="{backgroundColor: commentator.color}"
                >

                  <img :src="commentator.img" :alt="commentator.name[locale]"/>
                </div>
                <ul class="commentators-item-contacts">
                  <li>
                    <a
                      v-if="commentator.twitter"
                      :href="commentator.twitter"
                      target="_blank"
                      aria-label="twitter"
                    >
                      <SvgSprite symbol="icon-twitter" size="24 17"/>
                    </a>
                    <i
                      v-else
                      class="commentators-item-contact-empty"
                    ></i>
                  </li>
                  <li>
                    <AppPicture
                      v-if="commentator.flag"
                      :src="`flag-${commentator.flag}.png`"
                      width="22"
                    />
                    <i
                      v-else
                      class="commentators-item-contact-empty"
                    ></i>
                  </li>
                  <li>
                    <a
                      v-if="commentator.instagram"
                      :href="commentator.instagram"
                      target="_blank"
                      aria-label="instagram"
                    >
                      <SvgSprite symbol="icon-instagram" size="18"/>
                    </a>
                    <i
                      v-else
                      class="commentators-item-contact-empty"
                    ></i>
                  </li>
                </ul>
                <div
                  class="commentators-item-name"
                  v-html="commentator.name[locale].replace(' ', '<br>')"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import AppPicture from '@/components/AppPicture.vue';
import SliderArrows from '@/components/SliderArrows.vue';
import { commentators } from '@/data/commentators';

export default {
  name: 'HomeMaincast',
  components: {
    AppPicture,
    SliderArrows,
  },
  setup() {

    const { t, locale } = useI18n({ useScope: 'global' });

    const slider = ref(null);

    onMounted(() => {
      const initSlider = () => {
        if(window.matchMedia('(max-width:1023px)').matches){
          if(!slider.value.swiper){
            const sliderWrapp = slider.value.closest('.slider-wrapp');
            new Swiper(slider.value, {
              modules: [Navigation],
              speed: 400,
              navigation: {
                nextEl: sliderWrapp.querySelector('.slider-arrow-next'),
                prevEl: sliderWrapp.querySelector('.slider-arrow-prev'),
              },
              breakpoints: {
                0: {
                  slidesPerView: 1,
                  slidesPerGroup: 1,
                },
                564: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                828: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
              }
            });
          }
        }else{
          if(slider.value.swiper){
            slider.value.swiper.destroy(true, true);
          }
        }
      }
      initSlider();
      window.onresize = initSlider;
    });

    return {
      t,
      locale,
      slider,
      commentators,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.maincast{
  :deep(.maincast-img-title){
    margin-bottom:28px;
  }
}

.commentators-slider-wrapp{
  :deep(.slider-arrow){
    top:115px;
    @media @md_{
      display:none;
    }
  }
  :deep(.slider-arrow-prev){
    @media @xs_{
      left:-20px;
    }
  }
  :deep(.slider-arrow-next){
    @media @xs_{
     right:-20px;
    }
  }
  @media @md{
    padding:0 36px;
  }
}

.commentators-slider{
  .swiper-wrapper{
    @media @md_{
      transform:none!important;
      width:auto!important;
      flex-wrap:wrap;
      margin-bottom:-50px;
    }
  }
}

.commentators-slide{
  width:20%;
  margin-bottom:50px;
}

.commentators-item{
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
}



.commentators-item-img{
  width:230px;
  max-width:100%;
  margin-bottom:20px;
  border-radius:50%;
  overflow:hidden;
  will-change:transform;
  background-color:@link;
  &:before{
    content:'';
    padding-top:100%;
    display:block;
  }
}

.commentators-item-contacts{
  display:flex;
  align-items:center;
  margin:0 -10px 16px;
  li{
    padding:0 10px;
  }
  :deep(img){
    display:block;
  }
  a{
    display:block;
    color:@link_;
    transition:color .4s;
    &:hover{
      color:@link;
    }
    svg{
      display:block;
    }
  }
}

.commentators-item-contact-empty{
  display:block;
  width:10px;
  height:10px;
  border-radius:50%;
  background-color:@link_;
}

.commentators-item-name{
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  text-transform:uppercase;
  margin:0 16px;
  min-height:60px;
  span{
    color: var(--yellow);
  }
}

</style>
