<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <router-view/>
</template>

<script>
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';

export default {
  name: 'App',
  setup(){

    const { t, locale } = useI18n();

    useMeta({
      title: t('head.title'),
      htmlAttrs: {
        lang: locale.value,
      },
    });

  }
}
</script>

<style lang="less">
@import (less) '@/assets/less/variables.less';
@import (less) '@/assets/less/fonts.less';
@import (less) '@/assets/less/mixins.less';
@import (less) '@/assets/less/base.less';
@import (less) '@/assets/less/content.less';
@import (less) '@/assets/less/main.less';
</style>
