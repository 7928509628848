<template>
  <div
    id="about" 
    class="about section"
  >
    <div class="container">
      <div class="about-body">
        <div
          class="section-title"
          v-html="t('home.about.title')"
        ></div>
        <div class="about-media">
          <div class="about-img">
            <AppPicture
              src="about.png"
              alt="Volodymyr Zelenskyy"
            />
          </div>
          <img
            src="/img/u24-logo.svg"
            class="about-logo"
            alt="United24"
          >
        </div>
        <div
          class="about-content content-text"
          v-html="t('home.about.content')"
        ></div>
        <div
          class="about-total"
          v-html="t('home.about.total')"
        ></div>
      </div>
      <AppButton
        :href="donateLink"
        class="btn-full-width"
        target="_blank"
      >
        {{t('home.about.donateBtn')}}
      </AppButton>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import AppPicture from '@/components/AppPicture.vue';
import AppButton from '@/components/AppButton.vue';

export default {
  name: 'HomeAbout',
  components: {
    AppPicture,
    AppButton,
  },
  props: {
    donateLink: {
      type: String,
      default: '#',
    },
  },
  setup() {

    const { t } = useI18n({ useScope: 'global' });

    return {
      t,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.about-body{
  padding:42px 36px 66px;
  border-radius:35px;
  background: linear-gradient(180.04deg, #000000 0.03%, #010913 26.06%, @link 99.97%);
  display:flex;
  align-items:center;
  flex-direction:column;
  text-align:center;
  @media @md{
    padding:48px 28px 44px;
  }
  .section-title{
    margin-bottom:54px;
    @media @md{
      margin-bottom:40px;
    }
  }
}

.about-media{
  max-width:744px;
  display:flex;
  flex-direction:column;
  margin-bottom:48px;
}

.about-img{
  :deep(img){
    display:block;
    @media @md_{
      border-radius:35px;
    }
  }
}

.about-logo{
  margin-top:16px;
  align-self:flex-end;
  @media @md{
    margin-top:12px;
  }
}

.about-content{
  margin-bottom:48px;
  @media @md{
    margin-bottom:38px;
    :deep(br){
      display:none;
    }
  }
}

.about-total{
  font-size:64px;
  line-height:.8;
  text-transform:uppercase;
  letter-spacing: -0.025em;
  font-family:@ff_;
  :deep(span){
    white-space:nowrap;
  }
}

</style>
