<template>
  <div
    id="prizes" 
    class="prizes section"
  >
    <div class="container">
      <div class="section-head">
        <h2 class="section-title">{{t('home.prizes.title')}}</h2>
        <div
          class="section-desc"
          v-html="t('home.prizes.desc')"
        ></div>
      </div>
      <div class="prizes-row">
        <div
          v-for="(prize, index) in prizes"
          :key="index"
          class="prizes-item"
          :class="prize.bought === prize.count ? 'bought' : ''"
        >
          <div class="prizes-item-body">
            <a
              href="#"
              class="prizes-item-link"
              :aria-label="prize.title[locale]"
              @click.prevent="openPopup"
            ></a>
            <div class="prizes-item-content">
              <div class="prizes-item-title">{{prize.title[locale]}}</div>
              <div
                v-if="prize.bought !== prize.count"
                class="prizes-item-count">
                {{prize.count - prize.bought}}/{{prize.count}}
              </div>
              <div class="prizes-item-img cover-img">
                <AppPicture
                  v-if="prize.img"
                  :src="prize.img"
                />
              </div>
            </div>
            <div class="prizes-item-popup lottery-item-popup">
              <button
                type="button"
                class="prizes-item-popup-close"
                @click="closePopup"
                :aria-label="t('home.lottery.closeBtn')"
              >
                <SvgSprite symbol="icon-close" size="28"/>
              </button>
              <div class="prizes-item-popup-content">
                <div class="prizes-item-popup-title">{{prize.title[locale]}}</div>
                <div
                  class="prizes-item-popup-desc"
                  v-html="prize.desc[locale]"
                ></div>
              </div>
            </div>
          </div>
          <AppButton
            v-if="prize.bought !== prize.count"
            :href="prize.link"
            class="prizes-item-btn-buy btn-full-width"
            target="_blank"
          >
            <span class="prize-price-btn-text hidden-tablet">${{prize.price}}</span>
            <span class="prize-buy-btn-text">
              <span>{{t('home.prizes.buyBtn')}}</span>
              <span class="visible-tablet"> - ${{prize.price}}</span>
            </span>
          </AppButton>
          <AppButton
            v-else
            class="prizes-item-btn-out btn-full-width btn-border"
          >
            {{t('home.prizes.outBtn')}}
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import AppButton from '@/components/AppButton.vue';
import AppPicture from '@/components/AppPicture.vue';

export default {
  name: 'HomePrizes',
  props: {
    prizes: {
      type: Array,
      required: true,
    },
  },
  components: {
    AppButton,
    AppPicture,
  },
  setup() {

    const { t, locale } = useI18n({ useScope: 'global' });
    
    const openPopup = $event => {
      document.querySelectorAll('.prizes-item-body, .lottery-item-prizes').forEach(el => el.classList.remove('active'));
      $event.target.closest('.prizes-item-body').classList.add('active');
    }

    const closePopup = $event => {
      $event.target.closest('.prizes-item-body').classList.remove('active');
    }

    return {
      t,
      locale,
      openPopup,
      closePopup,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.prizes-row{
  display:flex;
  flex-wrap:wrap;
  margin-bottom:-50px;
  @media @xs{
    margin:0 calc(var(--container-padding) * -1) -50px;
  }
}

.prizes-item{
  display:flex;
  flex-direction:column;
  margin-bottom:50px;
  width:33.333%;
  @media @md{
    width:50%;
  }
  @media @sm{
    width:100%;
  }
  :deep(.prizes-item-btn-buy){
    @media @md_{
      position:relative;
      .prize-price-btn-text{
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        display:flex;
        align-items:center;
        justify-content:center;
        border-radius:inherit;
        transition:opacity .4s;
      }
      .prize-buy-btn-text{
        opacity:0;
        transition:opacity .4s;
      }
    }
  }
  :deep(.prizes-item-btn-out){
    pointer-events:none;
  }
  &:hover{
    :deep(.prizes-item-btn-buy){
      background-color:@link;
      color:@link_;
      @media @md_{
        .prize-price-btn-text{
          opacity:0;
        }
        .prize-buy-btn-text{
          opacity:1;
        }
      }
    }
    &:not(.bought){
      .prizes-item-content{
        &:after{
          opacity:1;
        }
      }
    }
  }
  &.bought{
    .prizes-item-content{
      &:before{
        opacity:0;
      }
    }
    .prizes-item-img{
      opacity:.3;
    }
  }
}

.prizes-item-body{
  flex:auto;
  display:flex;
  flex-direction:column;
  border-radius:35px;
  position:relative;
  perspective:1000px;
  &.active{
    .prizes-item-popup{
      transform:rotateY(0);
    }
    .prizes-item-content{
      transform:rotateY(-180deg);
    }
  }
}

.prizes-item-content{
  flex:auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  position:relative;
  z-index:6;
  padding:16px 28px 0;
  overflow:hidden;
  min-height:382px;
  transition:transform .4s;
  backface-visibility: hidden;
  will-change:transform;
  border-radius:35px;
  background: linear-gradient(139.67deg, var(--white) 1.75%, #8E8E8E 92.85%);
  &:before{
    content:'';
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(153.39deg, var(--white) 2.16%, @link_ 83.36%);
    transition:opacity .4s;
  }
  &:after{
    content:'';
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(153.39deg, var(--white) 2.16%, @link 83.36%);
    transition:opacity .4s;
    opacity:0;
  }
}

.prizes-item-title{
  color:var(--black);
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  margin-bottom:16px;
  position:relative;
  z-index:6;
}

.prizes-item-count{
  display:flex;
  align-items:center;
  justify-content:center;
  padding:0 8px;
  min-width:130px;
  border-radius:100px;
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  background-color:@link;
  color:@link_;
  height:50px;
  position:relative;
  z-index:6;
}

.prizes-item-img{
  height:264px;
  width:100%;
  margin-top:auto;
  position:relative;
  z-index:2;
  :deep(img){
    object-fit:contain;
  }
}

.prizes-item-link{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:9;
}

.prizes-item-popup{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  background-color:@link_;
  z-index:66;
  padding:20px 32px;
  display:flex;
  flex-direction:column;
  text-align:center;
  align-items:center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: transform 0.4s;
  will-change: transform;
  opacity:1;
  visibility:visible;
  pointer-events:auto;
  border-radius:35px;
  overflow:hidden;
}

.prizes-item-popup-close{
  position:absolute;
  right:28px;
  top:28px;
  color:@link;
  z-index:6;
  transition:color .4s;
  &:hover{
    color:var(--black);
  }
  :deep(svg){
    display:block;
  }
}

.prizes-item-desc{
  min-height:60px;
  line-height:1.2;
  color:var(--black);
  margin-top:auto;
  position:relative;
  z-index:6;
}

.prizes-item-popup-content{
  margin:auto 0;
  padding:28px 0;
}

.prizes-item-popup-title{
  font-size:36px;
  font-family:@ff_;
  line-height:.9;
  color:var(--black);
  margin-bottom:18px;
}

.prizes-item-popup-desc{
  color:var(--black);
  font-size:16px;
  line-height:1.2;
}

</style>
