<template>
  <div
    id="format" 
    class="format section"
  >
    <div class="container">
      <div class="section-head">
        <h2 class="section-title">{{t('home.format.title')}}</h2>
        <div class="section-desc">{{t('home.format.desc')}}</div>
      </div>
      <div class="members-slider-wrapp slider-wrapp">
        <SliderArrows/>
        <div
          class="members-slider swiper"
          ref="membersSlider"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(member, index) in members"
              :key="index"
              class="members-slide swiper-slide"
            >
              <div class="member-item">
                <div class="member-item-logo">
                  <img
                    src="/img/member-item-logo.svg"
                    alt="Navi"
                  >
                </div>
                <div class="member-item-name">{{member.name}}</div>
                <div class="member-item-img">
                  <AppPicture
                    :src="member.img"
                    :alt="member.name"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="teams"
        class="teams"
      >
        <div class="teams-desc">{{t('home.format.teamsDesc')}}</div>
        <div class="teams-slider-wrapp slider-wrapp">
          <SliderArrows/>
          <div
            class="teams-slider swiper"
            ref="teamsSlider"
          >
            <div class="swiper-wrapper">
              <div
                v-for="(team, index) in teams"
                :key="index"
                class="teams-slide swiper-slide"
              >
                <div class="teams-item">
                  <div class="teams-item-name">{{team.name}}</div>
                  <ul
                    class="teams-item-members"
                    :class="teamspeakUnlock ? 'unlock' : ''"
                  >
                    <li
                      v-for="(member, i) in team.members"
                      :key="i"
                      class="teams-slide swiper-slide"
                    >
                      <div class="teams-item-member">
                        <div class="teams-item-member-icon">
                          <AppPicture
                            :src="member.icon"
                            :width="member.iconWidth"
                          />
                        </div>
                        <div class="teams-item-member-name">{{member.name}}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import SliderArrows from '@/components/SliderArrows.vue';
import AppPicture from '@/components/AppPicture.vue';
import { scrollTo } from '@/helpers/functions';
import { members } from '@/data/members';
import { teams } from '@/data/teams';

export default {
  name: 'HomeMembers',
  components: {
    SliderArrows,
    AppPicture,
  },
  props: {
    teamspeakUnlock: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

    const { t } = useI18n({ useScope: 'global' });

    const membersSlider = ref(null);
    const teamsSlider = ref(null);

    onMounted(() => {
      const membersSliderWrapp = membersSlider.value.closest('.slider-wrapp');
      const membersSwiper = new Swiper(membersSlider.value, {
        modules: [Navigation],
        speed: 400,
        navigation: {
          nextEl: membersSliderWrapp.querySelector('.slider-arrow-next'),
          prevEl: membersSliderWrapp.querySelector('.slider-arrow-prev'),
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },
        on:{
          init: () => membersSliderWrapp.querySelector('.slider-arrow-next').removeAttribute('disabled'),
          realIndexChange: () => membersSliderWrapp.querySelector('.slider-arrow-next').removeAttribute('disabled'),
          touchEnd: () => {
            if(membersSwiper.isEnd){
              setTimeout(() => scrollTo('teams'), 200);
            }
          },
        }
      });
      
      membersSliderWrapp.querySelector('.slider-arrow-next').onclick = function(){
        if(membersSwiper.isEnd && !membersSwiper.animating){
          scrollTo('teams');
        }
      }

      const teamsSliderWrapp = teamsSlider.value.closest('.slider-wrapp');
      new Swiper(teamsSlider.value, {
        modules: [Navigation],
        speed: 400,
        navigation: {
          nextEl: teamsSliderWrapp.querySelector('.slider-arrow-next'),
          prevEl: teamsSliderWrapp.querySelector('.slider-arrow-prev'),
        },
        breakpoints: {
          0: {
            slidesPerView: 1,
            slidesPerGroup: 1,
          },
          480: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
          768: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
          1024: {
            slidesPerView: 4,
            slidesPerGroup: 4,
          },
        }
      });
    });


    return {
      t,
      membersSlider,
      teamsSlider,
      members,
      teams,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.format{
  position:relative;
  z-index:3;
}

.members-slider-wrapp{
  :deep(.slider-arrow){
    display:flex;
    &.slider-arrow-next.swiper-button-disabled{
      opacity:1;
      cursor:pointer;
    }
  }
}

.members-slide{
  display:flex;
  flex-direction:column;
}

.member-item{
  flex:auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  padding-top:28px;
  background-color:@link;
  border-radius:60px;
  overflow:hidden;
  will-change:transform;
  @media @md{
    border-radius:35px;
  }
}

.member-item-logo{
  margin:0 28px 20px;
  :deep(img){
    display:block;
  }
}

.member-item-name{
  font-size:36px;
  line-height:1.2;
  margin:0 28px;
  font-family:@ff_;
}

.member-item-img{
  margin-top:auto;
  width:382px;
  max-width:100%;
  :deep(img){
    display:block;
  }
}

.teams{
  margin-top:40px;
  @media @md{
    margin-top:44px;
  }
}

.teams-desc{
  margin-bottom:54px;
  text-align:center;
  font-size:24px;
  @media @md{
    margin-bottom:46px;
  }
}

.teams-slide{
  display:flex;
  flex-direction:column;
  @media @md_{
    &:nth-child(4n){
      .teams-item{
        margin-right:0;
      }
    }
  }
  @media @sm_ and @md{
    &:nth-child(3n){
      .teams-item{
        margin-right:0;
      }
    }
  }
  @media @xs_ and @sm{
    &:nth-child(2n){
      .teams-item{
        margin-right:0;
      }
    }
  }
}

.teams-item{
  flex:auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  padding:28px 16px 40px;
  border-radius:35px;
  overflow:hidden;
  will-change:transform;
  border:solid 2px @link_;
  @media @xs_{
    margin-right:-2px;
  }
}

.teams-item-name{
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  color:@link_;
  margin-bottom:32px;
  color:@link_;
  letter-spacing: -0.03em;
  @media @md{
    margin-bottom:20px;
  }
}

.teams-item-members{
  flex:auto;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  transition:filter .4s;
  user-select:none;
  &:not(.unlock){
    filter:blur(10px);
    pointer-events:none;
  }
  li{
    margin-bottom:12px;
    &:last-child{
      margin-bottom:0;
    }
  }
}

.teams-item-member{
  display:flex;
  align-items:center;
}

.teams-item-member-icon{
  width:52px;
  flex:none;
  margin-right:16px;
  :deep(img){
    display:block;
    margin:0 auto;
  }
}

.teams-item-member-name{
  min-width:1px;
  font-size:16px;
}

</style>
