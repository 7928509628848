export const members = [
  {
    name: 'sdy',
    img: 'member-img-1.png',
  },
  {
    name: 's1mple',
    img: 'member-img-2.png',
  },
  {
    name: 'b1t',
    img: 'member-img-3.png',
  },
];
