<template>
  <header
    class="header"
    :class="navIsOpen ? 'header-nav-is-open' : ''"
  >
    <button
      type="button"
      class="header-nav-open"
      :aria-label="t('header.ariaLabels.navOpen')"
      @click="toggleNav"
    >
      <i></i>
      <i></i>
      <i></i>
    </button>
    <a
      href="https://u24.gov.ua/"
      class="header-logo"
      target="_blank"
      aria-label="United24"
    >
      <img
        src="/img/u24-logo.svg"
        alt="United24"
        class="hidden-tablet"
      >
      <img
        src="/img/u24-logo-mobile.svg"
        alt="United24"
        class="visible-tablet"
      >
    </a>
    <div class="header-date hidden-tablet">{{t('header.date')}}</div>
    <div class="header-date visible-tablet">{{t('header.dateMobile')}}</div>
    <div class="header-donate">
      <AppButton
        class="header-donate-btn btn-small"
      >
        {{t('header.donateBtn')}}
      </AppButton>
      <ul class="header-donate-menu">
        <li>
          <AppButton
            :href="donateLink"
            class="btn-small btn-alt btn-full-width"
            target="_blank"
          >
            {{t('header.donateBtnClassic')}}
          </AppButton>
        </li>
        <li>
          <AppButton
            class="btn-small btn-alt btn-full-width"
            @click="openCryptoModal"
          >
            {{t('header.donateBtnCrypto')}}
          </AppButton>
        </li>
      </ul>
    </div>
  </header>
  <nav
    id="header-nav"
    class="header-nav modal"
    aria-hidden="true"
  >
    <div tabindex="-1" class="modal-wrapp">
      <div class="modal-body">
        <div
          id="header-nav-body"
          class="header-nav-body"
        >
          <ul
            class="header-menu tab-block"
            :class="rulesIsOpen ? '' : 'active'"
          >
            <li>
              <a
                href="#format"
                @click.prevent="scrollToBlock('#format')"
                class="header-link"
              >
                {{t('header.menu.format')}}
              </a>
            </li>
            <li>
              <a
                href="#donations-map"
                @click.prevent="scrollToBlock('#donations-map')"
                class="header-link"
              >
                {{t('header.menu.donationsMap')}}
              </a>
            </li>
            <li>
              <a
                href="#trip"
                @click.prevent="scrollToBlock('#trip')"
                class="header-link"
              >
                {{t('header.menu.lottery')}}
              </a>
            </li>
            <li>
              <a
                href="#prizes"
                @click.prevent="scrollToBlock('#prizes')"
                class="header-link"
              >
                {{t('header.menu.prizes')}}
              </a>
            </li>
            <li>
              <a
                href="#about"
                @click.prevent="scrollToBlock('#about')"
                class="header-link"
              >
                {{t('header.menu.about')}}
              </a>
            </li>
            <li>
              <a
                href="#"
                class="header-nav-rules-toggle header-link"
                @click="toggleRules"
              >
                {{t('header.rules.link')}}
              </a>
            </li>
          </ul>
          <div
            class="header-nav-rules tab-block"
            :class="rulesIsOpen ? 'active' : ''"
          >
            <div class="header-nav-rules-title header-link">{{t('header.rules.title')}}</div>
            <div
              class="header-nav-rules-content content-text"
              v-html="t('header.rules.content')"
            ></div>
            <AppButton
              class="btn-small btn-border header-nav-rules-close"
              @click="toggleRules"
            >
              {{t('header.rules.close')}}
            </AppButton>
          </div>
        </div>
        <ul
          class="header-langs tab-block"
          :class="rulesIsOpen ? '' : 'active'"
        >
          <li :class="locale === 'en' ? 'active' : ''">
            <router-link
              :to="langChangePath('en')"
              @click="changeLocale('en')"
              class="header-link"
            >
              en
            </router-link>
          </li>
          <li :class="locale === 'uk' ? 'active' : ''">
            <router-link
              :to="langChangePath('uk')"
              @click="changeLocale('uk')"
              class="header-link"
            >
              ua
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <div
    id="modal-donate-crypto"
    class="modal-donate-crypto modal"
    aria-hidden="true"
  >
    <div tabindex="-1" class="modal-wrapp">
      <div class="modal-body">
        <div class="donate-crypto-items">
          <div
            class="donate-crypto-item"
            v-for="(cryptoItem, key) in cryptoList"
            :key="key"
          >
            <div class="donate-crypto-item-qr-code">
              <AppPicture :src="cryptoItem.img"/>
            </div>
            <div class="donate-crypto-item-body">
              <div class="donate-crypto-item-title">
                <div class="donate-crypto-item-name">{{cryptoItem.title}}</div>
                <div
                  v-if="cryptoItem.chain"
                  class="donate-crypto-item-chain"
                >
                  ({{cryptoItem.chain}})
                </div>
              </div>
              <div class="donate-crypto-item-wallet">
                <div class="donate-crypto-item-wallet-title">{{cryptoItem.wallet}}</div>
                <AppButton
                  class="btn-small btn-border-gray donate-crypto-item-wallet-copy"
                  @click="copyToClipbord($event, cryptoItem.wallet)"
                >
                  <SvgSprite symbol="icon-checkbox" size="24"/>
                  <span>{{t('header.cryptoModal.copyBtn')}}</span>
                </AppButton>
              </div>
            </div>
          </div>
        </div>
        <div
          class="donate-crypto-info content-text"
          v-html="t('header.cryptoModal.info')"
        ></div>
        <AppButton
          class="btn-small btn-border-alt modal-donate-crypto-close"
          @click="closeCryptoModal"
        >
          {{t('header.cryptoModal.closeBtn')}}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { scrollTo } from '@/helpers/functions';
import AccessibleMinimodal from 'accessible-minimodal';
import AppButton from '@/components/AppButton.vue';
import AppPicture from '@/components/AppPicture.vue';

export default {
  name: 'TheHeader',
  components: {
    AppButton,
    AppPicture,
  },
  props: {
    donateLink: {
      type: String,
      default: '#',
    },
  },
  setup() {

    const route = useRoute();
    const { t, locale } = useI18n({ useScope: 'global' });

    const langChangePath = lang => {
			const pathArray = route.path.split('/');
			if(route.params.lang){
				if(lang === 'en'){
					pathArray.splice(1, 1);
					return pathArray.join('/');
				}
				pathArray[1] = lang;
				return pathArray.join('/');
			}
			if(lang !== 'en'){
				return '/' + lang + route.path;
			}
			return route.path;
		}

    const changeLocale = lang => {
      locale.value = lang;
      localStorage.setItem('locale', lang);
    };

    if(localStorage.getItem('locale')){
      changeLocale(localStorage.getItem('locale'));
    }
		
		if(!route.params.lang && locale.value !== 'en'){
			changeLocale('en');
		}

		if(route.params.lang && route.params.lang !== locale.value){
			changeLocale(route.params.lang);
		}

    const rulesIsOpen = ref(false);
    const navIsOpen = ref(false);

    const Modal = AccessibleMinimodal.init({
      style: {
        use: false
      },
      on: {
        beforeOpen: instance => {
          const scrollbarWidth = instance.getScrollbarWidth();
          document.querySelector('.header').style.marginRight = scrollbarWidth + 'px';
          if(instance.modal.id === 'header-nav'){
            navIsOpen.value = true;
          }
        },
        beforeClose: instance => {
          if(instance.modal.id === 'header-nav'){
            navIsOpen.value = false;
          }
        },
        afterClose: () => {
          document.querySelector('.header').style.marginRight = 0;
        }
      }
    });

    const toggleNav = () => {
      if(navIsOpen.value){
        Modal.closeModal();
      }else{
        Modal.openModal('header-nav');
      }
    };

    const scrollToBlock = (elementID) => {
      Modal.closeModal();
      scrollTo(elementID);
    }

    const toggleRules = $event => {
      rulesIsOpen.value = !rulesIsOpen.value;
      scrollTo('header-nav-body', $event.target.closest('.modal-wrapp'));
    }

    const cryptoList = [
      {
        img: 'qr-code-btc.png',
        title: 'BTC',
        chain: '',
        wallet: '1F47vVqZkVoaaodfNQF6H4R9RPu3tzTVoE',
      },
      {
        img: 'qr-code-eth.png',
        title: 'ETH',
        chain: 'ERC20',
        wallet: '0xfaed584952b6d08f7053505a735cc3a4f5ff253a',
      },
      {
        img: 'qr-code-usdt.png',
        title: 'USDT',
        chain: 'TRC20',
        wallet: 'TDJFzMvkuUd87sUEN5M7xrhoVuQhM2dp47',
      },
      {
        img: 'qr-code-busd.png',
        title: 'BUSD',
        chain: 'BEP20',
        wallet: '0xfaed584952b6d08f7053505a735cc3a4f5ff253a',
      },
    ];

    const openCryptoModal = () => {
      Modal.openModal('modal-donate-crypto');
    };

    const closeCryptoModal = () => {
      Modal.openModal();
    };

    const copyToClipbord = ($event, text) => {
      navigator.clipboard.writeText(text);
      $event.target.classList.add('active');
      setTimeout(() => $event.target.classList.remove('active'), 800);
    };

    return {
      t,
      locale,
      langChangePath,
      changeLocale,
      scrollToBlock,
      rulesIsOpen,
      navIsOpen,
      toggleNav,
      toggleRules,
      cryptoList,
      openCryptoModal,
      closeCryptoModal,
      copyToClipbord,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.header{
  --header-gap:38px;
  position:fixed;
  left:0;
  right:0;
  top:0;
  z-index:666;
  height:var(--header-height);
  display:flex;
  align-items:center;
  padding:0 var(--header-padding);
  transition:background-color .4s;
  font-family:@ff_;
  background-color:@filter;
  @media @md{
    --header-gap:30px;
  }
  &.header-nav-is-open{
    background-color:@link;
    .header-nav-open{
      i{
        opacity:0;
        transform:scale(0);
      }
      &:after{
        transform:scale(1);
      }
    }
  }
}

.header-nav-open{
  flex:none;
  display:flex;
  position:relative;
  margin-right:var(--header-gap);
  &:before{
    content:'';
    position:absolute;
    left:-8px;
    right:-8px;
    top:-16px;
    bottom:-16px;
  }
  &:after{
    content:'';
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    top:0;
    border-radius:8px;
    background-color:var(--white);
    transform:scale(0);
    transition:transform .4s;
  }
  i{
    width:13px;
    height:13px;
    border-radius:50%;
    background-color:@link;
    transition:background-color .4s,opacity .4s, transform .4s;
    @media @md{
      width:8px;
      height:8px;
    }
  }
  &:hover{
    i{
      background-color:@link_;
    }
  }
}

.header-logo{
  flex:none;
  transition:opacity .4s;
  @media @md{
    margin-right:12px;
  }
  &:hover{
    opacity:.8;
  }
  img{
    display:block;
  }
}

.header-date{
  margin:0 auto;
  font-size:36px;
  font-family:@ff_;
  line-height:.9;
  @media @md{
    font-size:24px;
  }
}

.header-donate{
  position:relative;
  @media @md_{
    width:calc(39px + var(--header-gap) + 184px);
    display:flex;
    flex-direction:column;
    align-items:flex-end;
  }
  @media @md{
    margin-right:calc(var(--header-padding) * -1);
  }
  &:hover{
    .header-donate-btn{
      &:after{
        transform:rotate(-180deg);
      }
    }
    .header-donate-menu{
      opacity:1;
      visibility:visible;
      pointer-events:auto;
    }
    :deep(.header-donate-btn){
      background-color:@link;
      color:@link_;
    }
  }
  :deep(.header-donate-btn){
    @media @md{
      width:140px;
      padding:0 24px;
    }
  }
}

.header-donate-btn{
  position:relative;
  @media @md{
    height:42px;
    font-size:24px;
  }
  &:after{
    content:'';
    position:absolute;
    border-top:solid 7px;
    border-left:solid 4px transparent;
    border-right:solid 4px transparent;
    right:16px;
    top:50%;
    margin-top:-4px;
    transition:transform .4s;
    @media @md{
      right:12px;
    }
  }
}

.header-donate-menu{
  position:absolute;
  right:0;
  top:100%;
  width:375px;
  padding:30px;
  border-radius:35px;
  background-color:@link_;
  transition:opacity .4s,visibility .4s;
  opacity:0;
  visibility:hidden;
  pointer-events:none;
  li{
    margin-bottom:20px;
    &:last-child{
      margin-bottom:0;
    }
  }
  :deep{
    .btn{
      &:hover{
        background-color:var(--black);
        color:@link_;
      }
    }
  }
}

.header-nav{
  z-index:660;
  font-family:@ff_;
  .modal-wrapp{
    background:none;
    padding:0;
  }
  .modal-body{
    background-color:@link;
    padding:calc(var(--header-height)) var(--header-padding) 40px;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    overflow:hidden;
    overflow-y:auto;
    color:var(--white);
    width:100%;
    min-height:100%;
    transform:translateY(100%);
  }
  &.active{
    .modal-body{
      transform:translateY(0);
    }
  }
}

.header-nav-body{
  margin:auto 0;
  padding:20px 0;
}

.header-menu{
  li{
    margin-bottom:20px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    &:last-child{
      margin-bottom:0;
    }
  }
}

.header-link{
  font-size:64px;
  line-height:.9;
  letter-spacing: -0.025em;
  color:inherit;
  text-transform:uppercase;
  display:block;
}

a.header-link{
  transition:color .4s;
  &:hover{
    color:@link_;
  }
}

.header-nav-foot{
  display:flex;
  min-height:1px;
  justify-content:space-between;
  flex:none;
}

.header-langs{
  display:flex;
  margin:0 -10px;
  align-self:flex-end;
  li{
    padding:10px;
    &.active{
      a{
        color:@link_;
        pointer-events:none;
      }
    }
  }
}

.header-nav-rules{
  display:flex;
  flex-direction:column;
  :deep(a){
    color:@link_;
  }
  :deep(.header-nav-rules-close){
    margin-top:20px;
    align-self:flex-end;
    padding:0 12px;
    min-width:130px;
    @media @md{
      align-self:center;
      height:50px;
    }
  }
}

.header-nav-rules-title{
  margin-bottom:38px;
  @media @md{
    margin-bottom:28px;
  }
}

.header-nav-rules-content{
  font-size:24px;
  max-width:1278px;
  @media @md{
    font-size:16px;
  }
}

.modal-donate-crypto{
  .modal-wrapp{
    @media @md{
      padding:0;
    }
  }
  .modal-body{
    width:1148px;
    border-radius:35px;
    padding:42px 50px 28px 28px;
    @media @md{
      width:100%;
      border-radius:0;
      padding:36px;
    }
  }
  :deep(.modal-donate-crypto-close){
    align-self:center;
    margin-top:54px;
    padding:0 12px;
    min-width:130px;
    @media @md{
      background-color:@link;
      color:@link_;
      border:0;
      height:50px;
      font-size:36px;
      margin-top:34px;
      &:hover{
        background-color:@link;
        color:@link_;
      }
    }
  }
}

.donate-crypto-item{
  display:flex;
  align-items:flex-start;
  margin-bottom:36px;
  @media @md{
    margin-bottom:30px;
    padding-bottom:70px;
    position:relative;
  }
  &:last-child{
    margin-bottom:0;
  }
}

.donate-crypto-item-qr-code{
  flex:none;
  width:86px;
  margin-right:30px;
  @media @md{
    margin-right:18px;
  }
  :deep(img){
    display:block;
  }
}

.donate-crypto-item-body{
  flex:auto;
  min-width:1px;
  align-self:center;
}

.donate-crypto-item-title{
  display:flex;
  align-items:baseline;
  color:@link;
  font-family:@ff_;
  text-transform:uppercase;
  line-height:.6;
  letter-spacing: -0.025em;
  margin-bottom:14px;
  @media @md{
    margin-bottom:8px;
  }
}

.donate-crypto-item-name{
  font-size: 64px;
  margin-right:12px;
  &:last-child{
    margin-right:0;
  }
}

.donate-crypto-item-chain{
  font-size: 36px;
}

.donate-crypto-item-wallet{
  display:flex;
  align-items:center;
  justify-content:space-between;
  :deep(.donate-crypto-item-wallet-copy){
    flex:none;
    padding:0 12px;
    min-width:130px;
    position:relative;
    svg{
      position:absolute;
      left:50%;
      top:50%;
      margin:-12px 0 0 -12px;
      opacity:0;
      transition:transform .4s,opacity .4s;
      opacity:0;
      transform:scale(0);
      pointer-events:none;
    }
    span{
      pointer-events:none;
      transition:transform .4s,opacity .4s;
    }
    &.active{
      background-color:@link;
      border-color:@link;
      color:@link_;
      svg{
        opacity:1;
        transform:scale(1);
      }
      span{
        opacity:0;
        transform:scale(0);
      }
    }
    @media @md{
      position:absolute;
      left:0;
      bottom:0;
      width:100%;
      background-color:@link;
      color:@link_;
      border:0;
      height:50px;
      font-size:36px;
      &:hover{
        background-color:@link;
        color:@link_;
      }
    }
  }
}

.donate-crypto-item-wallet-title{
  font-size:24px;
  line-height:1.3;
  word-break:break-all;
  margin-right:24px;
  flex:auto;
  min-width:1px;
  @media @md{
    margin:0;
    font-size:16px;
  }
}

.donate-crypto-info{
  margin-top:54px;
  max-width:840px;
  @media @md{
    max-width:100%;
    margin-top:44px;
    font-size:24px;
    line-height:1.3;
  }
}

</style>
