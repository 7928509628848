<template>
  <TheHeader
    :donateLink="donateLink"
  />
  <HomePromo
    :donateLink="donateLink"
  />
  <HomeBroadcast
    :donateLink="donateLink"
  />
  <HomeFormat
    :teamspeakUnlock="currentAmount >= 20000"
  />
  <HomeDonationsMap
    :userID='userID'
    :currentAmount="currentAmount"
    :donateLink="donateLink"
    :maps="maps"
  />
  <HomeTrip
    :leaderboard="leaderboard"
  />
  <HomeLottery
    :lotteryState="lotteryState"
    :prizes="lotteryPrizes"
  />
  <HomePrizes
    :prizes="prizes"
  />
  <HomeMaincast/>
  <HomeAbout
    :donateLink="donateLink"
  />
  <TheFooter/>
</template>

<script>
import { ref, onMounted } from 'vue';
import TheHeader from '@/components/TheHeader.vue';
import HomePromo from '@/components/HomePromo.vue';
import HomeBroadcast from '@/components/HomeBroadcast.vue';
import HomeFormat from '@/components/HomeFormat.vue';
import HomeDonationsMap from '@/components/HomeDonationsMap.vue';
import HomeTrip from '@/components/HomeTrip.vue';
import HomePrizes from '@/components/HomePrizes.vue';
import HomeLottery from '@/components/HomeLottery.vue';
import HomeMaincast from '@/components/HomeMaincast.vue';
import HomeAbout from '@/components/HomeAbout.vue';
import TheFooter from '@/components/TheFooter.vue';
import { getData } from '@/helpers/api';
import { lotteryPrizes as lotteryPrizesData } from '@/data/lotteryPrizes';
import { prizes as prizesData } from '@/data/prizes';
import { maps as mapsData } from '@/data/maps'

export default {
  name: 'TheHome',
  components: {
    TheHeader,
    HomePromo,
    HomeBroadcast,
    HomeFormat,
    HomeDonationsMap,
    HomeTrip,
    HomePrizes,
    HomeLottery,
    HomeMaincast,
    HomeAbout,
    TheFooter,
  },
  setup() {
    
    const donateLink = ref('#');
    const userID = ref('');
    const currentAmount = ref(0);
    const maps = ref([...mapsData]);
    const leaderboard = ref([]);
    const lotteryState = ref({});
    const lotteryPrizes = ref([]);
    const prizes = ref([]);

    onMounted(() => {
      getData('https://logitech.navi.gg/api/direct/brave?type=GetInfo').then(data => {
        if(!data){
          return;
        }
        if(location.hostname === 'localhost'){
          console.log(data);
        }
        if(data.donat_link){
          donateLink.value = data.donat_link;
        }
        if(data.total.now){
          currentAmount.value = data.total.now;
        }
        if(data.map_picking){
          const mapIndex = mapsData.findIndex(o => o.vote && o.vote.name === 'map_picking');
          mapsData[mapIndex].vote.list = [...data.map_picking];
          maps.value = [...mapsData];
        }
        if(data.map_veto){
          const mapIndex = mapsData.findIndex(o => o.vote && o.vote.name === 'map_veto');
          mapsData[mapIndex].vote.list = [...data.map_veto];
          maps.value = [...mapsData];
        }
        if(data.leader_list){
          leaderboard.value = data.leader_list;
        }
        if(data.donate_item_list){
          Object.keys(data.donate_item_list).forEach(key => {
            const lotteryIndex = lotteryPrizesData.findIndex(o => o.name === key);
            if(lotteryIndex !== -1){
              lotteryPrizesData[lotteryIndex].goal = data.donate_item_list[key].goal;
              lotteryPrizesData[lotteryIndex].now = data.donate_item_list[key].now;
              lotteryPrizesData[lotteryIndex].link = data.donate_item_list[key].link;
            }
            const prizeIndex = prizesData.findIndex(o => o.name === key);
            if(prizeIndex !== -1){
              prizesData[prizeIndex].count = data.donate_item_list[key].goal;
              prizesData[prizeIndex].bought = data.donate_item_list[key].goal - data.donate_item_list[key].now;
              prizesData[prizeIndex].link = data.donate_item_list[key].link;
            }
          });

          lotteryState.value = {... data.donate_item_list.x5_skins_superprize};
          lotteryPrizes.value = [...lotteryPrizesData];
          prizes.value = [...prizesData];

        }
      });

      window.onclick = $event => {
        if($event.target.classList.contains('prizes-item-link') || $event.target.classList.contains('lottery-item-img') || $event.target.classList.contains('map-item-title')){
          return
        }
        document.querySelectorAll('.prizes-item-body, .lottery-item-prizes').forEach(el => el.classList.remove('active'));
        document.querySelectorAll('.map-item').forEach(el => el.classList.remove('desc-is-visible'));
      }

    });


    return {
      donateLink,
      userID,
      currentAmount,
      maps,
      leaderboard,
      lotteryState,
      lotteryPrizes,
      prizes,
    }

  },
}

</script>

<style lang="less">
  
</style>
