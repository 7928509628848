export default {
  head: {
    title: "NAVI {'|'} BORN TO BE BRAVE",
  },
  header: {
    ariaLabels: {
      navOpen: 'Навігація',
    },
    date: '22 грудня',
    dateMobile: '22.12',
    donateBtn: 'підтримати',
    donateBtnClassic: 'ДОНАТ',
    donateBtnCrypto: 'ДОНАТ У КРИПТІ',
    cryptoModal: {
      closeBtn: 'ЗАКРИТИ',
      copyBtn: 'КОПІ',
      info: `
        <p>Пожертви в криптовалюті не можуть брати участь у благодійній лотереї.
        <p>Однак, якщо ви хочете придбати товар з розділу призів із фіксованною ціною, надішліть нам електронний лист, і ми вирішимо, як це зробити: <a href="mailto:brave{'@'}navi.gg" target="_blank">brave{'@'}navi.gg</a>
      `
    },
    menu: {
      format: 'Формат',
      donationsMap: 'Мапа донатів',
      lottery: 'Лотерея',
      prizes: 'Призи',
      about: 'КУДИ ПІДУТЬ ГРОШІ',
    },
    rules: {
      link: 'Правила',
      title: 'ЯК ДОНАТИТИ?',
      close: 'Закрити',
      content: `
        <p>Все просто! Ми пропонуємо три види донатів на підтримку України через UNITED24.
        <p>1. Ми цінуємо бажання кожного допомогти. Тому усі, навіть найменші, донати впливають на хід турніру, наближаючи відміткі голосувань на мапі донатів.
        <p>2. Усі донати, які беруть участь у лотереях із розіграшу призів мають бути кратній вказаній сумі. Чим більший донат, тим більше шансів виграти. Участь у лотереї можна взяти з 1 по 22 грудня. На стрімі ми оголосимо переможців.
        <p>3. Приз за донат: за донат на конкретну суму ти гарантовано отримуєш подарунок.
        <p style="color:var(--yellow);">ВАЖЛИВО:<br>
        В секції «коментар» в донорбоксі обов’язково впиши свій e-mail, щоб ми зв’язались із тобою у випадку виграшу! Усі зібрані кошти підуть на президентську ініціативу UNITED24 на медичні потреби України. Надсилай донати, отримуй подарунки та підтримуй Україну. Якщо ви юридична особа, яка хоче зробити пожертву, напишіть нам листа: <a href="mailto:brave{'@'}navi.gg" target="_blank">brave{'@'}navi.gg</a>
        `,
    }
  },
  home: {
    promo: {
      desc: `
        У свій
        <span data-text="День народження" data-title="13 років"></span>
        NAVI проводить благодійний турнір з CS:GO за участю<br> кращих кіберспортивних клубів та гравців, щоб зібрати донати для України з UNITED24
      `,
      donateBtn: 'зробити донат',
      scrollToBtn: 'Мапа донатів',
    },
    broadcast: {
      donateBtn: 'зробити донат',
      desc: 'Скористайся можливістю вплинути на хід турніру своїми<br> <span>донатами для України</span> та отримати <span>спеціальні призи</span>',
    },
    format: {
      title: '1 день, 4 команди та 20 гравців',
      desc: 'Твої улюблені карти та інтерактивні моди',
      teamsDesc: 'Ми оголосимо склади команд, як тільки відмітку Unlock Команд на мапі донатів буде досягнуто',
    },
    donationsMap: {
      title: 'Мапа донатів',
      desc: 'Впливати на хід турніру можуть вболівальники. Зроби внесок, щоб отримати<br> спеціальні призи та наближувати відмітки на мапі донатів.',
      btns: {
        open: 'Голосуй',
        save: 'Зберегти',
        close: 'Готово',
        results: 'Результат',
      },
      donateBtn: 'Зробити донат',
    },
    trip: {
      title: 'подорож на мажор',
      desc: 'Цей приз отримує самий щедрий фанат, який зробив внесків на найбільшу суму у рамках<br> BORN TO BE BRAVE. Донатити можна просто в загальний пул або на будь-який приз нижче.',
      info: {
        title: 'Переможець отримає:',
        content: `
        <ul>
          <li>VIP-квиток на Мажор в Парижі
          <li>Доступ до бекстейджу
          <li>Зустріч із командою в практіс рум
          <li>Мерч з автографами гравців
          <li>Квитки на літак першого класу та проживання в 5-зірковому готелі
          <li>Персональний водій на всі дні турніра
          <li>Прогулянка вздовж річки Сена та вечеря з амбасадорами GG.BET
          <li>Зустріч з Team Vitality та екскурсія до V.Hive
        </ul>
        `,
        desc: 'Якщо переможець не матиме можливості виїжджати за кордон, приз може буть змінено з його згоди на інший Мажор, коли кордоні відкриються.',
      },
      leaderboard: 'Топ фанів',
      place: 'PLACE',
    },
    lottery: {
      title: 'Лотерея для сміливих',
      desc: 'Зроби донат на обраний приз для участі в лотереї на вказану суму вхідного квитка. Ми оберемо переможців на стріми, коли загальні суми донатів кожного приза досягнуть позначеної цілі. Ти можешь зробити донати на декілька призів. Більше квитків — більше шансів на виграш.',
      prizesTitle: 'x3 скінів + суперприз',
      donateBtn: 'один квиток',
      buyBtn: 'ВЗЯТИ УЧАСТЬ',
      closeBtn: 'Закрити',
      now: 'Зібрано',
      goal: 'Відкрити лот',
      prizesPopupTitle: 'скіни + G PRO пак',
      prizesPopupDesc: 'Твій шанс виграти 1 із 3 скінів від  гравців NAVI CS:GO та набір від Logitech G:  PRO RACING WHEEL, PRO RACING PEDALS, G PRO X Superlight mouse, G PRO keyboard and G PRO X headset.',
    },
    prizes: {
      title: 'Гарантовані призи',
      desc: 'Якщо ти не любиш сюрприз, але обожнюєш подарунки,<br> цей сектор для тебе. Отримуй гарантовані призи за донат.',
      buyBtn: 'Купуй зараз',
      outBtn: 'Продано',
    },
    maincast: {
      desc: 'Партнер трансляції турніру — українська броадкастингова компанія Maincast.',
    },
    about: {
      title: 'КУДИ ПІДУТЬ ГРОШІ',
      content: `
        <p>UNITED24 був запущений Президентом України Володимиром Зеленським як основний майданчик для збору благодійних пожертв на підтримку України.
        <p>Кошти будуть перераховані на офіційні рахунки Національного банку України й будуть використані для купівлі медичного обладнання.      
      `,
      total: 'уже зібрано <span>$237 000 000+</span>',
      donateBtn: 'Зробити донат',
    },
  },
  footer: {
    terms: 'Terms and Conditions',
    link: 'https://drive.google.com/file/d/1nqeqlRKF0ucOJd5qCLcqnNzbezMcuWfE/view?usp=sharing',
    copyright: 'Natus Vincere 2009-2022 ©',
  },
}
