<template>
  <div
    id="broadcast" 
    class="broadcast section"
  >
    <div class="broadcast-decor broadcast-decor-left hidden-tablet">
      <img
        src="/img/broadcast-decor-left.png"
        alt=""
      >
    </div>
    <div class="broadcast-decor broadcast-decor-right hidden-tablet">
      <img
        src="/img/broadcast-decor-right.png"
        alt=""
      >
    </div>
    <div class="container">
      <div class="videos-slider-wrapp slider-wrapp">
        <SliderArrows/>
        <div
          class="videos-slider swiper"
          ref="slider"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(video, index) in broadcast"
              :key="index"
              class="videos-slide swiper-slide"
              :class="video.ids ? 'videos-slide-with-tabs' : ''"
              :data-index="index"
            >
              <div
                v-if="video.ids"
                class="video-tabs"
              >
                <div
                  v-for="(videoID, key) in video.ids"
                  :key="videoID"
                  class="video-tab tab-block"
                  :class="locale === key ? 'active' : ''"
                  :data-tab="key"
                >
                  <AppVideo
                    :source="video.source"
                    :id="videoID"
                  />
                  <div
                    v-if="video.desc"
                    class="videos-slide-desc"
                    v-html="video.desc[locale]"
                  ></div>
                </div>
                <ul class="video-tabs-nav">
                  <li
                    v-for="(videoID, key) in video.ids"
                    :key="videoID"
                    :class="locale === key ? 'active' : ''"
                  >
                    <a
                      href="#"
                      :data-tab="key"
                      @click.prevent="toggleTabs"
                    >
                      {{key}}
                    </a>
                  </li>
                </ul>
              </div>
              <template v-else>
                <AppVideo
                  :source="video.source"
                  :id="video.id"
                />
                <div
                  v-if="video.desc"
                  class="videos-slide-desc"
                  v-html="video.desc[locale]"
                ></div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div
        class="broadcast-desc"
        v-html="t('home.broadcast.desc')"
      ></div>
      <div class="broadcast-donate-btn-wrapp">
        <AppButton
          class="broadcast-donate-btn btn-full-width"
          :href="donateLink"
          target="_blank"
        >
          {{t('home.broadcast.donateBtn')}}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import Swiper, { Navigation } from 'swiper';
import 'swiper/css';
import AppVideo from '@/components/AppVideo.vue';
import AppButton from '@/components/AppButton.vue';
import SliderArrows from '@/components/SliderArrows.vue';
import { broadcast } from '@/data/broadcast'

export default {
  name: 'HomeBroadcast',
  components: {
    AppVideo,
    AppButton,
    SliderArrows,
  },
  props: {
    donateLink: {
      type: String,
      default: '#',
    },
  },
  setup() {

    const { t, locale } = useI18n({ useScope: 'global' });

    const slider = ref(null);

    onMounted(() => {
      const sliderWrapp = slider.value.closest('.slider-wrapp');
      const checkTabs = swiper => {
        if(slider.value.querySelector(`[data-index="${swiper.realIndex}"]`).classList.contains('videos-slide-with-tabs')){
          sliderWrapp.classList.add('tabs-active');
        }else{
          sliderWrapp.classList.remove('tabs-active');
        }
      }
      new Swiper(slider.value, {
        modules: [Navigation],
        speed: 400,
        spaceBetween: 32,
        autoHeight: true,
        navigation: {
          nextEl: sliderWrapp.querySelector('.slider-arrow-next'),
          prevEl: sliderWrapp.querySelector('.slider-arrow-prev'),
        },
        on: {
          init: swiper => checkTabs(swiper),
          realIndexChange: swiper => checkTabs(swiper),
        }
      });

      const startPlayer = () => {
        if(location.hostname === 'localhost'){
          return;
        }
        const rect = sliderWrapp.getBoundingClientRect();
        const win = sliderWrapp.ownerDocument.defaultView;
        const height = sliderWrapp.offsetHeight / 2;
        if(window.scrollY + window.innerHeight > rect.top + win.pageYOffset + height && !sliderWrapp.dataset.played){
          sliderWrapp.dataset.played = true;
          sliderWrapp.querySelector('.video-player-poster').click();
        }
      }

      startPlayer();
      window.onscroll = startPlayer;
      window.onresize = startPlayer;

    });

    const toggleTabs = $event => {
      const tabs = $event.target.closest('.video-tabs');
      const list = $event.target.closest('.video-tabs-nav');
      const tab = $event.target.dataset.tab;
      tabs.querySelectorAll('.video-tab').forEach(el => el.classList.remove('active'));
      list.querySelectorAll('li').forEach(el => el.classList.remove('active'));
      $event.target.closest('li').classList.add('active');
      tabs.querySelector(`.video-tab[data-tab="${tab}"]`).classList.add('active');
    }

    return {
      t,
      slider,
      broadcast,
      locale,
      toggleTabs,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.broadcast{
  position:relative;
  z-index:2;
}

.broadcast-decor{
  position:absolute;
  pointer-events:none;
  img{
    max-width:none;
  }
}

.broadcast-decor-left{
  top:-180px;
  right:50%;
  margin-right:-96px;
  @media @xl_{
    margin-right:120px;
  }
}

.broadcast-decor-right{
  top:80px;
  left:50%;
  margin-left:268px;
  @media @xl_{
    margin-left:380px;
  }
}

.videos-slider-wrapp{
  &.tabs-active{
    :deep(.slider-arrow){
      @media @xl_{
        bottom:80px;
      }
      @media @xl{
        top:calc(50% - 70px);
      }
    }
  }
}

.video-tabs-nav{
  display:flex;
  li{
    width:50%;
  }
  a{
    display:flex;
    align-items:center;
    justify-content:center;
    height:80px;
    padding:0 32px;
    border-radius:100px;
    font-family:@ff_;
    font-size:36px;
    line-height:1;
    color:var(--white);
    border:solid 2px @link;
    transition:color .4s,background-color .4s;
    @media @md{
      height:70px;
    }
    &:hover{
      color:@link;
    }
  }
  .active{
    a{
      background-color:@link;
      pointer-events:none;
    }
  }
}

.videos-slide-desc,
.broadcast-desc{
  text-align:center;
  padding:44px 0 36px;
  font-family:@ff_;
  font-size:36px;
  line-height:.9;
  position:relative;
  z-index:6;
  @media @md{
    font-size:24px;
    padding:30px 0;
    :deep(br){
      display:none;
    }
  }
  :deep(span){
    color:@link_;
  }
  &:empty{
    display:none;
  }
}

.broadcast-donate-btn-wrapp{
  @media @xs{
    margin:0 calc(var(--container-padding) * -1)
  }
}

</style>
