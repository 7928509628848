const myHeaders = new Headers();
myHeaders.append("Accept", "application/json");
myHeaders.append("Content-Type", "application/json");

export async function getData(point){
  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };
  try{
    const response = await fetch(point, requestOptions);
    if(!response.ok){
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    let data = await response.text();
    return responseData(data);
  }catch(error){
    console.log('Fetch error: ', error);
    return {};
  }
}

export async function putData(point, raw){
  if(typeof raw === 'object'){
    raw = JSON.stringify(raw);
  }
  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };
  try{
    const response = await fetch(point, requestOptions);
    if(!response.ok){
      const message = `An error has occured: ${response.status}`;
      throw new Error(message);
    }
    let data = await response.text();
    return responseData(data);
  }catch(error){
    console.log('Fetch error: ', error);
    return false;
  }
}

export function responseData(data){
  if(!data){
    return {};
  }
  if(typeof data === 'string'){
    try{
      data = JSON.parse(data);
    }catch(e){
      console.log(e);
      data = {};
    }
  }
  return data;
}
