export const lotteryPrizes = [
  {
    name: 'meet_simple',
    title: {
      en: 'Meet s1mple',
      uk: 'Зустріч з S1mple',
    },
    desc: {
      en: 'A unique opportunity to chat with<br> the legend in Discord for an hour, ask important questions, and play 1:1 or Wingman.',
      uk: 'Унікальна можливість поспілкуватися з легендою у Discord протягом години, постав важливі питання та зіграй разом 1:1 або Wingman.',
    },
    price: 25,
    img: 'prizes-img-1.png',
    goal: 0,
    now: 0,
    link: '#',
  },
  {
    name: 'signed_ukrainian_flag',
    title: {
      en: 'Signed Ukrainian flag',
      uk: 'Прапор України',
    },
    desc: {
      en: 'This whole event is dedicated to the bravery of Ukraine. This is your opportunity to get a Ukrainian flag signed by the President of Ukraine, Volodymyr Zelenskyy.',
      uk: 'Цей турнір присвячений мужності України. Твоя можливість отримати український прапор з підписом Президента України Володимира Зеленського.',
    },
    price: 100,
    img: 'prizes-img-8.png',
    goal: 0,
    now: 0,
    link: '#',
  },
  {
    name: 'x5_born_to_be_brave_ring',
    title: {
      en: 'x5 Born to be Brave Ring',
      uk: 'x5 Перстнів Born to be Brave',
    },
    desc: {
      en: 'Five rings are five symbols of the unity of this tournament and Ukraine. Limited edition: Only you and the BORN TO BE BRAVE tournament winners will own this ring.',
      uk: 'П\'ять перснів — символів єдності цього турніру та України. Обмежене видання: тільки для вас і переможців турніру BORN TO BE BRAVE.',
    },
    price: 50,
    img: 'prizes-img-3.png',
    goal: 0,
    now: 0,
    link: '#',
  },
  {
    name: 'mvp_medal',
    title: {
      en: 's1mple’s MVP Medal',
      uk: 'MVP медаль від s1mple',
    },
    desc: {
      en: 's1mple\'s medal from a tournament that personally has sentimental value to him.',
      uk: 'Медаль s1mple з турніру, що має важливу цінність для нього особисто.',
    },
    price: 100,
    img: 'prizes-img-10.png',
    goal: 0,
    now: 0,
    link: '#',
  },
  {
    name: 'x5_s1mples_steam_sign',
    title: {
      en: 'x5 S1mple’s steam sign',
      uk: 'x5 S1mple’s steam sign',
    },
    desc: {
      en: 'A unique opportunity to get s1mple’s steam sign. A very rare thing made specifically for this tournament. He usually doesn\'t do this.',
      uk: 'Зазвичай s1mple не залишає підписи в Steam, але спеціально для цього турніру зробив виняток. Дуже рідкісна можливість.',
    },
    price: 25,
    img: 'prizes-img-6.png',
    goal: 0,
    now: 0,
    link: '#',
  },
  {
    name: 'watch_from_blast',
    title: {
      en: 'Watch from Blast',
      uk: 'Годинник з Blast',
    },
    desc: {
      en: 'The limited edition TW Steel esports watch was specifically made for the winners of BLAST Premier Global Final 2021 and provided to you by our coach, B1ade.',
      uk: 'Обмежена серія годинників TW Steel була створена спеціально для переможців фіналу BLAST Premier Global Final 2021.',
    },
    price: 50,
    img: 'prizes-img-4.png',
    goal: 0,
    now: 0,
    link: '#',
  },
  {
    name: 'signed_navi_flag',
    title: {
      en: 'Signed NAVI flag',
      uk: 'Прапор, підписаний гравцями CS:GO на Мажорі в Ріо.',
    },
    desc: {
      en: 'The flag signed by the top players from the Major in Rio.',
      uk: '',
    },
    price: 25,
    img: 'prizes-img-5.png',
    goal: 0,
    now: 0,
    link: '#',
  },
];