export const broadcast = [
  {
    source: 'youtube',
    id: 'xZnHRLwGpt8',
  },
  // {
  //   source: 'youtube',
  //   ids: {
  //     'en': 'KCZxTZ_N2Nk',
  //     'uk': 'tqpPPP9PqfE',
  //   },
  // },
];
