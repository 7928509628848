<template>
  <div
    id="trip" 
    class="trip section"
  >
    <div class="container">
      <div class="section-head">
        <div class="section-title">{{t('home.trip.title')}}</div>
        <div
          class="section-desc"
          v-html="t('home.trip.desc')"
        ></div>
      </div>
      <div class="trip-main">
        <div class="trip-info">
          <div class="trip-info-img">
            <AppPicture
              src="trip-img.png"
              width="285"
              alt=""
            />
          </div>
          <div class="trip-info-body">
            <div class="trip-title">{{t('home.trip.info.title')}}</div>
            <div
              class="trip-info-content content-text"
              v-html="t('home.trip.info.content')"
            ></div>
            <div
              class="trip-info-desc"
              v-html="t('home.trip.info.desc')"
            ></div>
          </div>
        </div>
        <div class="trip-leaderboard">
          <div class="trip-title">{{t('home.trip.leaderboard')}}</div>
          <table class="trip-leaderboard-table">
            <tr
              v-for="(user, index) in leaderboard"
              :key="index"
            >
              <td>{{index + 1}} {{t('home.trip.place')}}</td>
              <td>{{user.name}} - ${{user.amount}}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import AppPicture from '@/components/AppPicture.vue';

export default {
  name: 'HomeTrip',
  props: {
    leaderboard: {
      type: Array,
    },
  },
  components: {
    AppPicture,
  },
  setup() {

    const { t } = useI18n({ useScope: 'global' });

    return {
      t,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';


.trip-main{
  @media @md_{
    display:flex;
  }
  @media @xs{
    margin:0 calc(var(--container-padding) * -1);
  }
}

.trip-info{
  width:66.666%;
  padding:32px 26px 32px 32px;
  background: rgba(0, 0, 0, 0.15);
  border: 2px solid var(--red);
  box-shadow: inset 0px 0px 20px var(--red), inset 0px 0px 39px var(--red);
  border-radius:35px;
  overflow:hidden;
  will-change:transform;
  display:flex;
  align-items:center;
  @media @md{
    width:100%;
    padding:32px;
    display:block;
  }
}

.trip-info-img{
  flex:none;
  margin-right:12px;
   @media @md{
    margin:0 0 20px;
   }
  :deep(img){
    display:block;
    margin:0 auto;
  }
}

.trip-info-body{
  flex:auto;
  min-width:1px;
}

.trip-title{
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  margin-bottom:16px;
  text-transform:uppercase;
}

.trip-info-content{
  font-size:16px;
  margin-bottom:16px;
}

.trip-info-desc{
  font-size:12px;
  line-height:1.2;
  margin-left:-8px;
}

.trip-leaderboard{
  width:33.333%;
  padding:32px 32px 40px;
  border:solid 2px var(--white);
  border-radius:35px;
  @media @md{
    width:100%;
  }
  .trip-title{
    text-align:center;
    margin-bottom:28px;
  }
}

.trip-leaderboard-table{
  margin:0 auto;
  td{
    padding:0 16px 4px 0;
    &:last-child{
      padding-right:0;
    }
  }
  tr{
    &:first-child{
      color:var(--red);
    }
    &:last-child{
      td{
        padding-bottom:0;
      }
    }
    @media @md{
      &:nth-child(n+6){
        display:none;
      }
    }
  }
}

</style>
