<template>
  <div
    id="lottery" 
    class="lottery section"
  >
    <div class="container">
      <div class="section-head">
        <h2 class="section-title">{{t('home.lottery.title')}}</h2>
        <div
          class="section-desc"
          v-html="t('home.lottery.desc')"
        ></div>
      </div>
      <div class="lottery-prizes">
        <div class="lottery-prizes-body prizes-item-body lottery-item">
          <a
            href="#"
            class="prizes-item-link"
            :aria-label="t('home.lottery.prizesPopupTitle')"
            @click.prevent="openPopup"
          ></a>
          <div class="prizes-item-popup lottery-item-popup">
            <button
              type="button"
              class="prizes-item-popup-close"
              @click="closePopup"
              :aria-label="t('home.lottery.closeBtn')"
            >
              <SvgSprite symbol="icon-close" size="28"/>
            </button>
            <div class="prizes-item-popup-content">
              <div class="prizes-item-popup-title">{{t('home.lottery.prizesPopupTitle')}}</div>
              <div
                class="prizes-item-popup-desc"
                v-html="t('home.lottery.prizesPopupDesc')"
              ></div>
            </div>
          </div>
          <div class="prizes-item-content lottery-prizes-content">
            <div class="lottery-prizes-head">
              <div class="lottery-prizes-title">{{t('home.lottery.prizesTitle')}}</div>
              <AppPicture
                  src="lottery-powered-by.png?v2"
                  alt="powered by"
                  width="72"
                />
            </div>
            <LotteryProgress
              :goal="lotteryState ? lotteryState.goal : 0"
              :now="lotteryState ? lotteryState.now : 0"
            />
            <div class="lottery-items">
              <div
                v-for="(item, index) in lottery"
                :key="index"
                class="lottery-item lottery-item-prizes"
              >
                <div
                  v-if="item.title"
                  class="lottery-item-popup"
                >
                  <button
                    type="button"
                    class="lottery-item-popup-close"
                    @click="closePopup"
                    :aria-label="t('home.lottery.closeBtn')"
                  >
                    <SvgSprite symbol="icon-close" size="28"/>
                  </button>
                  <div class="lottery-item-popup-title">{{item.title[locale]}}</div>
                  <div class="lottery-item-popup-img">
                    <AppPicture
                      :src="`lottery-desktop-${index + 1}.png?v2`"
                      :alt="item.title[locale]"
                      width="1149"
                      class="hidden-tablet"
                    />
                    <AppPicture
                      :src="`lottery-mobile-${index + 1}.png?v2`"
                      :alt="item.title[locale]"
                      width="375"
                      class="visible-tablet"
                    />
                  </div>
                </div>
                <a
                  href="#"
                  class="lottery-item-img"
                  :aria-label="item.title ? item.title[locale] : t('home.lottery.prizesPopupTitle')"
                  @click.prevent="item.title ? openPopup($event) : $event.target.closest('.lottery-prizes-body').querySelector('.prizes-item-link').click()"
                >
                  <AppPicture
                    :src="`lottery-desktop-preview-${index + 1}.png?v2`"
                    :alt="item.title ? item.title[locale] : t('home.lottery.prizesPopupTitle')"
                    :width="item.imgWidth"
                    class="hidden-tablet"
                  />
                  <AppPicture
                      :src="`lottery-mobile-preview-${index + 1}.png?v2`"
                      :alt="item.title ? item.title[locale] : t('home.lottery.prizesPopupTitle')"
                      width="375"
                      class="visible-tablet"
                    />
                </a>
              </div>
            </div>
          </div>
        </div>
        <AppButton
          :href="lotteryState ? lotteryState.link : '#'"
          class="lottery-btn btn-full-width"
          target="_blank"
        >
          <span class="lottery-price-btn-text">
            {{t('home.lottery.donateBtn')}} - $5
          </span>
          <span class="lottery-buy-btn-text">
            {{t('home.lottery.buyBtn')}} - $5
          </span>
        </AppButton>
      </div>
      <div class="prizes-row">
        <div
          v-for="(prize, index) in prizes"
          :key="index"
          class="lottery-prizes prizes-item"
          :class="prize.now >= prize.goal ? 'active' : ''"
        >
          <div class="prizes-item-body lottery-item">
            <a
              href="#"
              class="prizes-item-link"
              :aria-label="prize.title[locale]"
              @click.prevent="openPopup"
            ></a>
            <div class="prizes-item-content">
              <div class="prizes-item-title">{{prize.title[locale]}}</div>
              <LotteryProgress
                :goal="prize.goal"
                :now="prize.now"
              />
              <div class="prizes-item-img cover-img">
                <AppPicture
                  v-if="prize.img"
                  :src="prize.img"
                />
              </div>
            </div>
            <div class="prizes-item-popup lottery-item-popup">
              <button
                type="button"
                class="prizes-item-popup-close"
                @click="closePopup"
                :aria-label="t('home.lottery.closeBtn')"
              >
                <SvgSprite symbol="icon-close" size="28"/>
              </button>
              <div class="prizes-item-popup-content">
                <div class="prizes-item-popup-title">{{prize.title[locale]}}</div>
                <div
                  class="prizes-item-popup-desc"
                  v-html="prize.desc[locale]"
                ></div>
              </div>
            </div>
          </div>
          <AppButton
            :href="prize.link"
            class="lottery-btn btn-full-width"
            target="_blank"
          >
            <span class="lottery-price-btn-text">
              {{t('home.lottery.donateBtn')}} - ${{prize.price}}
            </span>
            <span class="lottery-buy-btn-text">
              {{t('home.lottery.buyBtn')}} - ${{prize.price}}
            </span>
          </AppButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import AppButton from '@/components/AppButton.vue';
import AppPicture from '@/components/AppPicture.vue';
import LotteryProgress from '@/components/LotteryProgress.vue';
import { lottery } from '@/data/lottery'

export default {
  name: 'HomeLottery',
  components: {
    AppButton,
    AppPicture,
    LotteryProgress,
  },
  props: {
    prizes: {
      type: Array,
      required: true,
    },
    donateLink: {
      type: String,
      default: '#',
    },
    lotteryState: {
      type: Object,
      required: true,
    },
  },
  setup() {

    const { t, locale } = useI18n({ useScope: 'global' });

    const openPopup = $event => {
      document.querySelectorAll('.prizes-item-body, .lottery-item-prizes').forEach(el => el.classList.remove('active'));
      $event.target.closest('.lottery-item').classList.add('active');
    }

    const closePopup = $event => {
      $event.target.closest('.lottery-item').classList.remove('active');
    }

    return {
      t,
      locale,
      lottery,
      openPopup,
      closePopup,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.lottery-prizes{
  @media @xs{
    margin:0 calc(var(--container-padding) * -1);
  }
  :deep(.lottery-btn){
    position:relative;
    .lottery-price-btn-text{
      position:absolute;
      left:0;
      right:0;
      top:0;
      bottom:0;
      display:flex;
      align-items:center;
      justify-content:center;
      border-radius:inherit;
      transition:opacity .4s;
    }
    .lottery-buy-btn-text{
      opacity:0;
      transition:opacity .4s;
    }
  }
  &:hover{
    :deep(.lottery-btn){
      background-color:@link;
      color:@link_;
      .lottery-price-btn-text{
        opacity:0;
      }
      .lottery-buy-btn-text{
        opacity:1;
      }
    }
    .prizes-item-content{
      &:after{
        opacity:1;
      }
    }
  }
  .prizes-item-popup-desc{
    max-width:380px;
  }
}

.lottery-prizes-content.prizes-item-content{
  pointer-events:none;
  z-index:66;
}

.lottery-prizes-head{
  display:flex;
  margin-bottom:20px;
  align-items:center;
  justify-content:center;
  position:relative;
  z-index:1;
  :deep(img){
    display:block;
  }
}

.lottery-prizes-title{
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  margin-right:12px;
  color:var(--black);
  &:last-child{
    margin:0;
  }
}

.lottery-items{
  display:flex;
  align-items:center;
  margin-top:32px;
  @media @md{
    flex-direction:column;
  }
}

.lottery-item{
  flex:auto;
  min-width:1px;
  display:flex;
  flex-direction:column;
  pointer-events:auto;
  &.active{
    &>.lottery-item-popup{
      opacity:1;
      visibility:visible;
      pointer-events:auto;
    }
  }
}

.lottery-item-prizes{
  display:flex;
  justify-content:center;
  align-items:center;
  @media @md_{
    height:252px;
  }
  @media @md{
    margin-bottom:8px;
    &:last-child{
      margin-bottom:0;
    }
  }
}

.lottery-item-img{
  position:relative;
  z-index:6;
  min-width:1px;
  :deep(picture){
    pointer-events:none;
    display:block;
  }
  :deep(img){
    transition:transform .4s;
    pointer-events:auto;
    pointer-events:none;
    @media @lg_{
      max-width:none;
    }
  }
  &:hover{
    :deep(img){
      @media @md_{
        transform:scale(1.08);
      }
    }
  }
}

.lottery-item-popup{
  position:absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  background-color:@link_;
  z-index:66;
  padding:36px;
  display:flex;
  flex-direction:column;
  opacity:0;
  visibility:hidden;
  pointer-events:none;
  transition:opacity .4s,visibility .4s;
  @media @md{
    padding:24px 32px 96px; 
  }
}

.lottery-item-popup-close,
.prizes-item-popup-close{
  position:absolute;
  right:28px;
  top:28px;
  color:@link;
  z-index:6;
  transition:color .4s;
  &:hover{
    color:var(--black);
  }
  :deep(svg){
    display:block;
  }
}

.lottery-item-popup-title{
  padding:0 32px;
  text-align:center;
  min-height:50px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  margin-bottom:24px;
  color:var(--black);
  @media @md{
    min-height:0;
  }
}

.lottery-item-popup-img{
  flex:auto;
  min-height:1px;
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  margin:0 -36px -36px;
  @media @md{
    margin:0 -24px -16px;
  }
  :deep(img){
    display:block;
    margin:0 auto;
  }
}

.prizes-row{
  display:flex;
  justify-content:center;
  flex-wrap:wrap;
  margin:50px 0 -50px;
  @media @xs{
    margin:50px calc(var(--container-padding) * -1) -50px;
  }
}

.prizes-item{
  display:flex;
  flex-direction:column;
  margin-bottom:50px;
  width:33.333%;
  @media @md{
    width:50%;
  }
  @media @sm{
    width:100%;
  }
  &.active{
    .prizes-item-content{
      &:before{
        opacity:1;
      }
    }
  }
  &:hover{
    .prizes-item-content{
      &:after{
        opacity:1;
      }
    }
  }
}

.prizes-item-body{
  flex:auto;
  display:flex;
  flex-direction:column;
  border-radius:35px;
  position:relative;
  perspective:1000px;
  &.active{
    .prizes-item-popup{
      transform:rotateY(0);
    }
    .prizes-item-content{
      transform:rotateY(-180deg);
    }
  }
}

.prizes-item-content{
  flex:auto;
  display:flex;
  flex-direction:column;
  align-items:center;
  text-align:center;
  position:relative;
  z-index:6;
  padding:16px 28px 0;
  overflow:hidden;
  min-height:382px;
  transition:transform .4s;
  backface-visibility: hidden;
  will-change:transform;
  border-radius:35px;
  background: linear-gradient(139.67deg, var(--white) 1.75%, #8E8E8E 92.85%);
  &:before{
    content:'';
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(153.39deg, var(--white) 2.16%, @link_ 83.36%);
    transition:opacity .4s;
    opacity:0;
  }
  &:after{
    content:'';
    position:absolute;
    left:0;
    right:0;
    top:0;
    bottom:0;
    background: linear-gradient(153.39deg, var(--white) 2.16%, @link 83.36%);
    transition:opacity .4s;
    opacity:0;
  }
}

.prizes-item-title{
  color:var(--black);
  font-size:36px;
  line-height:.9;
  font-family:@ff_;
  margin-bottom:20px;
  position:relative;
  z-index:6;
}

.prizes-item-img{
  height:250px;
  width:320px;
  max-width:100%;
  margin-top:auto;
  position:relative;
  z-index:2;
  :deep(img){
    object-fit:contain;
  }
}

.prizes-item-link{
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:9;
}

.prizes-item-popup{
  padding:20px 32px;
  display:flex;
  flex-direction:column;
  text-align:center;
  align-items:center;
  transform: rotateY(180deg);
  backface-visibility: hidden;
  transition: transform 0.4s;
  will-change: transform;
  opacity:1;
  visibility:visible;
  pointer-events:auto;
  border-radius:35px;
  overflow:hidden;
}

.prizes-item-desc{
  min-height:60px;
  line-height:1.2;
  color:var(--black);
  margin-top:auto;
  position:relative;
  z-index:6;
}

.prizes-item-popup-content{
  margin:auto 0;
  padding:20px 0;
}

.prizes-item-popup-title{
  font-size:36px;
  font-family:@ff_;
  line-height:.9;
  color:var(--black);
  margin-bottom:18px;
}

.prizes-item-popup-desc{
  color:var(--black);
  font-size:16px;
  line-height:1.2;
}

</style>
