import { createApp } from 'vue'
import App from './App.vue'

import router from '@/router';
import { createI18n } from 'vue-i18n';
import messages from '@/languages/messages';
import { createMetaManager } from 'vue-meta';
import { svgSpritePlugin } from 'vue-svg-sprite'

const i18n = createI18n({
  legacy: false,
  locale: navigator.languages && navigator.languages[0] ? navigator.languages[0].split('-')[0].toLowerCase() : 'en',
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
});

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(svgSpritePlugin, {
  url: '/img/svg-sprite.svg',
})

app.use(createMetaManager(false, {
  meta: {
    tag: 'meta',
    nameless: true,
  },
  htmlAttrs: {
    attributesFor: 'html',
  },
}));

app.mount('#app');
