export const prizes = [
  {
    name: 'custom_puma_sneakers',
    title: {
      en: 'CUsTOM PUMA SNEAKERS',
      uk: 'Кастомні Кросівки',
    },
    desc: {
      en: 'Custom UNITED24 x NAVI x PUMA<br> sneakers. Limited edition of only 10 pairs. Customed by Sneakermate.',
      uk: 'Лімітована серія кросівок<br> UNITED24 x NAVI x PUMA —<br> тільки 10 пар. Кастомізовано<br> Sneakermate.',
    },
    count: 0,
    bought: 0,
    price: 1000,
    img: 'prizes-img-9.png',
    link: '#',
  },
  {
    name: 'gaming_chair',
    title: {
      en: 'Gaming Chair',
      uk: 'Ігрове Крісло',
    },
    desc: {
      en: 'Unique BACKFORCE NAVI x UNITED24<br> designed chair. Limited <br> edition of only 5 chairs.',
      uk: 'Унікальне крісло BACKFORCE<br> в дизайні NAVI x UNITED24, обмежений дроп — всього 5 айтемів.',
    },
    count: 0,
    bought: 0,
    price: 2000,
    img: 'prizes-img-2.png',
    link: '#',
  },
  {
    name: 'exclusive_hoodie',
    title: {
      en: 'NAVI hoodie',
      uk: 'худі NAVI',
    },
    desc: {
      en: 'Your $200 donation reward<br> from NAVI. Limited collection of only 200 hoodies.',
      uk: 'Твоя винагорода за донат в розмірі $200 від NAVI, обмежена колекція 200 худі.',
    },
    count: 0,
    bought: 0,
    price: 200,
    img: 'prizes-img-7.png',
    link: '#',
  },
];