<template>
  <footer class="footer">
    <div class="container">
      <a
        href="https://drive.google.com/file/d/1-iQoLT4o1-NbCJ_QVqk1drViKMK0TEaP/view?usp=share_link"
        target="_blank"
        class="footer-link"
      >{{t('footer.terms')}}</a>
      <div class="footer-copyright">{{t('footer.copyright')}}</div>
    </div>
  </footer>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default {
  name: 'TheFooter',
  setup() {

    const { t } = useI18n({ useScope: 'global' });

    return {
      t,
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.footer{
  margin-top:auto;
  background-color:#171717;
  padding:16px 0;
  font-size:14px;
  line-height:1.4;
  .container{
    display:flex;
    justify-content:space-between;
    align-items:center;
    @media @xs{
      padding:0 20px;
    }
  }
}

.footer-link{
  margin-right:20px;
  color:@link_;
  transition:color .4s;
  &:hover{
    color:@link;
  }
}

</style>
