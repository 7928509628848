<template>
  <picture>
    <source
      :srcset="`/img/${src.split('.').shift()}.webp 1x, /img/${src.split('.').shift()}@2x.webp 2x`"
      type="image/webp"
    >
    <img
      :src="`/img/${src}`"
      :srcset="`/img/${src.split('.').shift()}@2x.${src.split('.').pop()} 2x`"
      :width="width"
      :class="className"
      :alt="alt || ''"
    >
  </picture>
</template>

<script>

// import { ref } from 'vue'

export default {
  name: 'AppPicture',
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
    },
    target: {
      type: String,
    },
    className: {
      type: String,
    },
    alt: {
      type: String,
    },
  },
  setup() {

    return {
      
    }
  },
}
</script>

<style lang="less" scoped>

</style>
