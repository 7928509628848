<template>
  <button
    class="slider-arrow slider-arrow-prev"
    aria-label="Prev"
  >
    <SvgSprite symbol="icon-arrow-left" size="32 16"/>
  </button>
  <button
    class="slider-arrow slider-arrow-next"
    aria-label="Next"
  >
    <SvgSprite symbol="icon-arrow-right" size="32 16"/>
  </button>
</template>

<script>

// import { ref } from 'vue'

export default {
  name: 'SliderArrows',
}
</script>

<style lang="less" scoped>
  @import (less) '@/assets/less/variables.less';
  .slider-arrow{
    --slider-arrow-width:144px;
    position:absolute;
    top:0;
    bottom:0;
    width:var(--slider-arrow-width);
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:100px;
    color:var(--white);
    transition:.4s;
    z-index:6;
    @media @xl{
      width:60px;
      height:60px;
      border-radius:50%;
      background:none;
      top:50%;
      margin-top:-30px;
      bottom:auto;
      svg{
        width:22px;
        height:15px;
      }
    }
    &:hover{
      @media @xl_{
        background-color:rgba(255,255,255,.1);
      }
    }
    path{
      fill:currentColor;
    }
    &.swiper-button-disabled{
      color:var(--white);
      pointer-events:auto;
      opacity:.3;
      @media @xl{
        background:none;
      }
    }
    &.swiper-button-lock{
      display:none;
    }
  }
  .slider-arrow-prev{
    left:calc(var(--slider-arrow-width) * -1);
    @media @xl{
      left:0;
    }
  }
  .slider-arrow-next{
    right:calc(var(--slider-arrow-width) * -1);
    @media @xl{
      right:0;
    }
  }
</style>
