<template>
  <a
    v-if="href"
    class="btn"
    :href="href"
    :target="target ? target : ''"
    :class="className"
    :disabled="disabled"
    :aria-label="ariaLabel ? ariaLabel : ''"
  >
    <slot></slot>
  </a>
  <button
    v-else
    class="btn"
    :type="type ? type : 'button'"
    :class="className"
    :disabled="disabled"
    :aria-label="ariaLabel ? ariaLabel : ''"
  >
    <slot></slot>
  </button>
</template>

<script>

// import { ref } from 'vue'

export default {
  name: 'AppButton',
  props: {
    href: {
      type: String,
    },
    type: {
      type: String,
    },
    target: {
      type: String,
    },
    className: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    ariaLabel: {
      type: Boolean,
    },
  },
  setup() {

    return {
      
    }
  },
}
</script>

<style lang="less" scoped>
@import (less) '@/assets/less/variables.less';

.btn{
  display:inline-flex;
  align-items:center;
  justify-content:center;
  font-family:@ff_;
  text-transform:uppercase;
  position:relative;
  border:0;
  line-height:1;
  cursor:pointer;
  height:80px;
  padding:0 52px;
  background-color:@link_;
  color:@text;
  font-size:36px;
  transition:color .4s,background-color .4s,opacity .4s,visibility .4s,border-color .4s;
  border-radius:100px;
  max-width:100%;
  @media @md{
    height:70px;
    padding:0 40px;
  }
  *{
    pointer-events:none;
  }
  &:hover{
    background-color:@link;
    color:@link_;
  }
}

.btn-small{
  height:50px;
}

.btn-full-width{
  display:flex;
  width:100%;
}

.btn-alt{
  background-color:@link;
  color:@link_;
  &:hover{
    background-color:@link_;
    color:@link;
  }
}

.btn-border{
  background:none;
  border:solid 2px @link_;
  color:@link_;
  &:hover{
    background-color:@link_;
    color:var(--black);
  }
}

.btn-border-alt{
  background:none;
  border:solid 2px @link;
  color:@link;
  &:hover{
    background-color:@link;
    color:@link_;
  }
}

.btn-border-gray{
  background:none;
  border:solid 2px #C9C9C9;
  color:#C9C9C9;
  &:hover{
    border-color:@link;
    background-color:@link;
    color:@link_;
  }
}

</style>
