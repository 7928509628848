export const maps = [
  {
    sum: 350000,
    sumTitle: '$350k',
    img: 'donations-map-img-1.png',
    title: {
      en: 'TEAMSPEAK UNLOCK',
      uk: 'Тімспік Unlock',
    },
    desc: {
      en: 'Now you can hear what\'s going on behind the players’ screens.',
      uk: 'Тепер ти можеш почути все, що відбувається за екранами гравців.',
    },
  },
  {
    sum:400000,
    sumTitle: '$400k',
    img: 'donations-map-img-2.png',
    title: {
      en: 'ROSTERS UNLOCK',
      uk: 'Unlock команд',
    },
    desc: {
      en: 'Reach this milestone to see rosters of the participating teams',
      uk: 'При досягненні цієї відмітки, ми оголосимо склад команд-учасниць.',
    },
  },
  {
    sum: 450000,
    sumTitle: '$450k',
    img: 'donations-map-img-3.png',
    title: {
      en: 'MAP PICK',
      uk: 'MAP PICK',
    },
    desc: {
      en: 'Select  map pool<br> of the tournament.',
      uk: 'Обери пул мап<br> турніру.',
    },
    vote: {
      name: 'map_picking',
      list: [],
      checkedCount: 7,
    },
  },
  {
    sum: 500000,
    sumTitle: '$500k',
    img: 'donations-map-img-4.png',
    title: {
      en: 'MAP VETO',
      uk: 'MAP VETO',
    },
    desc: {
      en: 'Select maps for<br> the Grand Final.',
      uk: 'Обирай мапи<br> на Гранд-фінал.',
    },
    vote: {
      name: 'map_veto',
      list: [],
      checkedCount: 3,
    },
  },
];
